@import '~/src/utils/_variables.scss';

.header-main {
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px 0px #00000026;
  z-index: 1;
  height: 110px;
  .header-item {
    flex-basis: 33.3%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    &.menu {
      justify-content: flex-start;
    }
    &.contact {
      justify-content: flex-end;
    }
  }
  .cloudinary-logo {
    svg {
      width: 170px;
      height: 40px;
    }
  }
  .contact-us.btn.primary {
    background-color: #ff620c;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    height: 42px;
    line-height: 42px;
    padding: 0 40px;
  }
  @media ($mq-mobile) {
    flex-wrap: wrap;
    height: auto;
    justify-content: flex-start;
    align-items: end;
    padding: 20px 30px;
    .header-item {
      margin-top: 0;
      &.logo {
        order: 1;
        flex-basis: 100%;
        margin-bottom: 10px;
      }
      &.menu {
        order: 2;
        margin-right: auto;
        button {
          padding: 0;
        }
      }
      &.contact {
        order: 3;
        flex-basis: auto;
        .contact-us.btn.primary {
          padding: 0 20px;
          height: 32px;
        }
      }
    }
    .cloudinary-logo {
      svg {
        width: 140px;
        height: 30px;
      }
    }
  }
}
