@import '~/src/utils/_variables.scss';

.range-slider-container {
  display: flex;
  align-items: center;
  height: 18px;
  margin-left: 15px;
  .label,
  .value {
    font-size: 13px;
    line-height: 13px;
  }
  .value {
    width: 30px;
  }
  .slider {
    width: 120px;
    margin-left: 20px;
    margin: 0 16px 0 20px;
    display: flex;
    align-items: center;
    .MuiSlider-rail {
      color: $grey;
      height: 8px;
      opacity: 0.9;
    }
    .MuiSlider-track {
      color: $primary;
      height: 8px;
      border: none;
    }
    .MuiSlider-thumb {
      border: none;
      height: 16px;
      width: 16px;
      color: $white;
      &::after {
        height: 10px;
      }
    }
    .Mui-disabled {
      .MuiSlider-rail {
        opacity: 0.3;
      }
      .MuiSlider-track {
        opacity: 0.5;
      }
      .MuiSlider-thumb {
        color: rgb(221, 221, 221);
      }
    }
  }
}
