@import '../../utils/utils';
@import '~/src/utils/_variables.scss';

:root {
  --primary: #0d9aff;
  --background: #090c0f;
  --surface: #1f242e;
  --surfaceAlt: #14181e;
  --contrastLow: #d1d6e0;
  --secondaryAlt: #535f7a;
  --sofia-pro: 'sofia-pro', sans-serif;
  --inter: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: var(--inter);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 14px;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

.App {
  display: flex;
  flex-direction: row;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    min-height: 100vh;
    max-width: 100%;
    transition: max-width 0.2s, min-height 0.2s;
    background-color: $greyLight;
  }

  .content {
    width: 100%;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  // Buttons
  .btn {
    @include button-reset;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    transition: opacity 0.25s;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
      filter: brightness(1.1);
    }

    &[disabled] {
      opacity: 0.5;
    }

    &.primary {
      background-color: $primary;
      color: $white;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 400;
      height: 24px;
      padding: 0 12px;
    }

    &.secondary {
      color: $primary;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 400;
      height: 24px;
      padding: 0 12px;
    }

    &.inverted {
      background-color: transparent;
      color: var(--primary);
    }

    &.light {
      color: var(--contrastLow);
    }
  }
}

// Scrollbar
/* Firefox */
.scroller {
  scrollbar-width: thin;
  scrollbar-color: $primary;
}
/* Chrome, Edge, and Safari */
.scroller::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.scroller::-webkit-scrollbar-track {
  background: transparent;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 20px;
  border: 2px solid $white;
}

// Page Template
.page-container {
  flex: 1;
  max-width: 1015px;
  background-color: $greyLight;
  padding: 120px 20px 120px 20px;
  @media ($mq-mobile) {
    max-width: 90%;
    padding: 80px 0 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
