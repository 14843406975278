@import '~/src/utils/_variables.scss';

.text-field-container {
  margin-left: 25px;
  width: 100%;
  .MuiInputBase-root {
    background-color: $greyBlue;
    border-radius: 4px;
    height: 24px;
    font-size: 13px;
    padding: 0 10px;
  }
}
