@import '~/src/utils/_variables.scss';

.top-section {
  border-bottom: 1px solid rgba($black, 0.25);
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  .page-info {
    flex-basis: 50%;
    padding-right: 50px;
    box-sizing: border-box;
    .back-button {
      margin-bottom: 30px;
      .btn-icon {
        margin-right: 6px;
      }
    }
    .title {
      font-size: 42px;
      line-height: 50px;
      margin-bottom: 19px;
    }
    .subtitle {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 30px;
    }
    .detailed {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .asset-select-container {
    flex-basis: 50%;
  }
  .controls-section {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    .controls {
      display: flex;
      margin-left: auto;
      align-items: center;
    }
  }
  @media ($mq-mobile) {
    width: 90%;
    justify-content: center;
    .page-info {
      flex-basis: 100%;
      padding-right: 0;
      .detailed {
        margin-bottom: 20px;
      }
    }
    .asset-select-container {
      width: 100%;
      flex-basis: auto;
    }
    .controls-section {
      flex-wrap: wrap;
      .title {
        flex-basis: 100%;
        margin-bottom: 10px;
      }
      .controls {
        margin-left: 0;
      }
    }
  }
}
