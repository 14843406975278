@import '~/src/utils/_variables.scss';

.ai-zoompan-page>.top-section #current-asset {display: none;}
.ai-zoompan-page> .bottom-section .asset-select {display: none;}

.page-container.ai-zoompan-page, .ai-zoompan-page #current-asset {
    background-color: rgb(16, 25, 38);
}

.ai-zoompan-page .bottom-section {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #101926;
}

.bottom-section>.top-section .asset-select-container {
  flex-basis: 100%;
}
.ai-zoompan-page .bottom-section {
  gap: 20px;
}

.ai-zoompan-page .bottom-section>.top-section {
    padding: 0;
    width: 100%;
}

.ai-zoompan-page #current-asset {
  margin-bottom: 0;
  border-radius: 0;
}

.ai-zoompan-page .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://cloudinary-marketing-res.cloudinary.com/image/upload/v1713452788/videoapi-demo-tessellation.svg);
  z-index: 0;
}

.ai-zoompan-page .photo-select-container .header, /*.photo-select-container .current-asset*/, .ai-zoompan-page .top-section .page-info .back-button, .ai-zoompan-page .controls-section .title, .ai-zoompan-page .page-info {
  display: none;
}

.page-container.ai-zoompan-page .bottom-section .loading-asset {
  max-height: 260px!important;
}

.ai-zoompan-page .point-selection {
    display: block;
    height: 40px;
    position: absolute;
    width: 40px;
    z-index: 10000;
    border: 2px solid rgb(80, 255, 21);
    border-radius: 100%;
    transform: translate(-20px, -20px);
}
.ai-zoompan-page .bottom-section img {
  width: calc(50% - 10px);
}
.ai-zoompan-page #current-asset {
  position: relative;
}
.ai-zoompan-page #current-asset img {
  width: 100%;
  max-width: 600px;
}
.ai-zoompan-page .docs-parent {
  margin-bottom: 80px !important; 
}
.ai-zoompan-page .docs-parent a {
  color: white;
  text-decoration: none;
}
.ai-zoompan-page .docs-parent a:hover {
  text-decoration: underline;
}
.ai-zoompan-page .top-section {
  padding: 30px;
  padding-bottom: 10px;
  border-bottom: 0;
  background-color: rgba(16, 25, 38, 1);
  flex-wrap: nowrap;
}
.ai-zoompan-page .top-section .page-info {
    flex-basis: 100%;
}
.ai-zoompan-page .top-section .controls-section {
    flex-basis: 50%;
    margin: 0;
}
.ai-zoompan-page .bottom-section {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #101926;
}
.ai-zoompan-page .extra {
  background-color: #101926;
  padding: 20px 30px;
  color: white;
  color: var(--Greys-30, #D5DDE6);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 24px */
}

.ai-zoompan-page .hero-section {
    padding-bottom: 60px;
}

.ai-zoompan-page .select-container div, .label {
  color: white;
}
.ai-zoompan-page .select-container div fieldset, .ai-zoompan-page .select-container div fieldset:hover {
  border-color: white!important;
  color: white;
}
.ai-zoompan-page .select-container div svg {
  color: white;
}
.ai-zoompan-page .photo-select-container .asset-select .scroller-container::after {
  display: none;
}

.ai-zoompan-page .photo-select-container .asset-select .scroller-container .scroller .asset-option {
    width: 85px;
    height: 70px;
}
.ai-zoompan-page .asset-option img {
    object-fit: cover;
    width: auto;
    height: 100%;
}

.hero {
    width: 100%;
    max-width: 1215px;
    margin: auto;
    padding: 30px;
}
.hero .tag {
  color: var(--primary-turquoise-blue-turquoise-60, #48C4D8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  text-transform: uppercase;
}
.hero h1 {
  color: var(--Greys-White, #FFF);
  font-family: Inter;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 56.4px */
}
.hero p {
  color: var(--Greys-30, #D5DDE6);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 24px */
}
.page-container {
    flex: 1 1;
    max-width: 1215px!important;
    background-color: #fafafc;
    padding: 0px!important;
}

.hero-section {
  width: 100%;
  background: var(--Gradients-Stormy-Sky, linear-gradient(150deg, #0C163B 15.12%, #18246F 56.41%, #6F447E 94.45%));
  padding-top: 80px;
  position: relative;
  min-height: 100vh;
}
.ai-zoompan-page {
  margin: auto;
}

.ai-zoompan-page .photo-select-container .asset-select .upload-button {
  width: 110px;
  height: auto;
  background: transparent;
  margin: 0;
}
.ai-zoompan-page .docs-parent {
  padding: 30px;
  margin-bottom: 60px;
  background-color: rgba(16, 25, 38, 1);
  color: white;
  font-family: "Roboto Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.ai-zoompan-page .docs-parent p:nth-of-type(2) {margin-bottom: 30px;}
.ai-zoompan-page .docs-parent p {
   word-break:break-all;
}
.ai-zoompan-page .orange {
  color: #F7BC00;
}
.ai-zoompan-page .photo-select-container .asset-select .scroller-container::before {
  display: none;
}
.ai-zoompan-page .photo-select-container .asset-select .scroller-container .scroller .asset-option.selected {
    outline: 2px solid var(--48-c-4-d-8-accentturquoise, #48C4D8);
    outline-offset: -2px;
}
.ai-zoompan-page .photo-select-container .asset-select .scroller-container .scroller .asset-option, .upload-button {
  transition: all 100ms ease-out!important;
}
.ai-zoompan-page .photo-select-container .asset-select .scroller-container .scroller .asset-option:hover, .upload-button:hover {
  transform: scale(1.1);
}
.ai-zoompan-page .photo-select-container .asset-select .scroller-container .scroller {
    overflow-x: auto;
}
.ai-zoompan-page .photo-select-container .asset-select .scroller-container .scroller .asset-option, .photo-select-container .asset-select .scroller-container {
  overflow: visible;
}
.asset-option img {border-radius: 5px;}

.links {
  color: white;
  padding: 30px 30px 40px 30px;
  margin-bottom: 20px;
}
.links a {
  color: #48C4D8;
}
.docs-parent {
  margin-bottom: 0!important;
}

.page-container.ai-zoompan-page {
  .controls-section {
    .apply-button {
      margin-left: 25px;
    }
  }
  .bottom-section {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading-asset {
      background-color: #fff;
      display: flex;
      height: 540px;
      width: 100%;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      .loading-text {
        margin-left: 10px;
        line-height: 16px;
      }
    }
  }




  .links {
    padding-bottom: 40px!important;
  }
  .bottom-section {
    position: relative;
    padding-top: 60px;
  }
  .bottom-section:after {
      content: "Results are generated in real time (can take up to few minutes).";
      display: block;
      color: white;
      width: calc(100% - 60px);
      margin: auto;
      padding: 10px 0;
      background: #101926;
      box-sizing: border-box;
      color: var(--Greys-30, #d5dde6);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      max-width: 1115px;
      position: absolute;
      top: 0;
      margin-left: 30px;
  }
  .bottom-section img {
    width: 100%;
  }
  .links {
      padding-bottom: 0px !important;
      color: #FFF;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
  }
  
  .current-asset img {
    width: 100%;
  }
  .bottom-section {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .bottom-section > video {
    width: 100%;
    max-width: 600px;;
  }



  @media ($mq-mobile) {
    .controls-section {
      .controls {
        width: 100%;
      }
      .apply-button, .select-container {
        margin-left: 20px;
      }      
    }
  }

  @media (max-width: 767px) {
    .top-section {
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      padding-top: 10px;
    }
    .asset-select .scroller-container {
      overflow: hidden;
    }
    .top-section .controls-section {
      margin-top: 10px;
    }
    .bottom-section {
      display: grid;
      grid-template-columns: 100%;
    }
    .bottom-section img {
      width: 100%;
    }
    .docs-parent {
      padding-top: 10px;
    }
    .photo-select-container .asset-select {
      padding: 0 10px;
    }
    .links {
        width: 100%;
        box-sizing: border-box;
    }
    .bottom-section:after {
      margin-left: 30px;
    }
  }
}
@media (max-width: 767px) {
  #header #header-inner .header-frame .container-fluid {
    padding: 0 10px;
  }
  .hero-section {
    padding-top: 30px;
  }
  .hero h1 {
    font-size: 38px;
  }
  .hero-section .hero {
    width: auto;
  }
}

.row.grid {margin-bottom: 100px;}