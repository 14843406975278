.navbar-brand img{
    max-width: 220px;
}

#mainNav ul li a{
    color: #FFF;
    font-family: var(--standard-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;

}

p small{
    color: var(--primary-turquoise);
    font-size: 12px;
    font-style: italic;
}



header li a{
    text-transform: uppercase;
    margin-left: 12px;
}

.signup-btn, .signup-btn:hover {
    background-color: var(--cta-blue);
    color: #fff;
    padding: 10px 40px;
    border-radius: 20px;
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
    font-family: var(--standard-font);
}

/* home page specific */
/* end homepage psecific */

/* demo header / title */
.demoHeader h1, #homeBanner h1{
    font-size: 47px;
    font-style: normal;
    font-weight: 500;
    font-family: var(--standard-font);
}

.demoHeader h2, .cta h3{
    font-family: var(--standard-font);
    margin-top: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: var(--primary-turquoise);
    text-transform: uppercase;
}

.demoHeader h4, #demoList.home  p{
    font-family: var(--standard-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: var(--standard-line-height);
}

#demoList.home  p{
    margin-bottom: 90px;
    font-family: var(--standard-font);
}

/* end demo header / titles */

/* demo main interface */

#demoInterface{
    background-color: var(--background-dark);
    padding: 40px;
    font-family: var(--standard-font);
}

/* end demo main interface */

.nav-link{
    color: #fff;
}

.alert-primary.login{
    color: #FFFFFF;
    background-color: var(--cta-blue);
    border: 0px;
    border-radius: 15px;
    padding: 20px;

}

.alert-primary.login a{

    color: #fff;
}
.btn-outline.float-end{
    display: block;
    border: 1px solid #fff;
    position: relative;
    top: -6px;
    color: #fff;
    padding: 3px 8px;
}




/* Style for text inputs */
.form-control, .form-select {
    background-color: var(--background-mid);
    border: 1px solid var(--accent-blue-gray);
    border-radius: 0.25rem;
    color: #e0e0e0; /* Slightly brighter text color */
    font-size: 12px;
    font-weight: 300;
    padding: 0.675rem 0.75rem;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.form-control::placeholder {
    color: #a0a0a0; /* Lighter placeholder text color */
}

label{
    color: #ccc; /* Slightly brighter text color */
    font-size: small;
    font-weight: 300;
}

nav a.active{
    font-weight: bold;
    line-height: 120%;
    color: var(--accent-turquoise) !important;
    border-bottom: 2px solid var(--accent-turquoise);
}

.form-control:focus {
    border-color: var(--accent-turquoise);
    background-color: var(--background-standard); /* Lighter background on focus */
    box-shadow: 0 0 0 0.25rem rgba(72, 196, 216, 0.25);
    color: #e0e0e0; /* Slightly brighter text color */
}

/* Style for checkbox inputs */
.form-check-input[type="checkbox"] {
    background-color: var(--background-dark);
    border: 1px solid var(--accent-blue-gray);
    border-radius: 0.25rem;
    margin-top: 0.25rem;
}

.form-check-input[type="checkbox"]:checked {
    background-color: var(--primary-turquoise);
    border-color: var(--primary-turquoise);
}

/* Style for checkbox label (optional) */
.form-check-label {
    font-family: var(--standard-font);
    font-size: small;
    font-weight: 300;
    color: #e0e0e0; /* Slightly brighter text color */
    margin-left: 0.5rem;
}

/* Additional styles */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--primary-turquoise);
    border-color: var(--primary-turquoise);
}

nav a{
    color: #fff;
}



.sample-image{
    border: 1px solid #fff;
}

.selected .sample-image{
    border: 2px solid var(--accent-turquoise);
}

.nav-link.active{font-weight: bold; color: crimson }

/* DEMO LIST STYLES */
#demoList{
    font-family: var(--standard-font);
    border-top: 1px solid var(--accent-turquoise);
    margin-top: 110px;
}

#demoList.home{
    border-top: 0;
    margin-top: 0;
}

#demoList.home h2{
    font-size: 47px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 56.4px */
    margin-bottom: 20px;
}



#demoList .demoListing article{
    background-color: var(--background-dark);
    border-radius: 20px;
    padding: var(--interface-padding);
}



#demoList h2{

    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: var(--standard-line-height); /* 43.2px */
    margin: 55px 0 70px;
}

.demoListing a{
    color: #fff;
    text-decoration: none;

}

#demoList .demoListing h3{

    /* Desktop/Headers/H6 */
    font-family: var(--standard-font);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: var(--standard-line-height); /* 25.2px */
    margin-bottom: 10px;
}


/* END DEMO LIST STYLES */

/* captions, special styles */

.captionBox{
    background-color: var(--background-light);
    border-radius: 20px;
    padding: 30px;
}

.captionBox h3, .captionBox p{
    font-family: var(--standard-font);
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: var(--standard-line-height);

}

.captionBox p{
    font-weight: 400;
}

/* END CAPTION Special Styles */

/* code output */

.btn-outline-primary{
    color: var(--accent-turquoise);
    border-color: var(--accent-turquoise);
    position: relative;
    top: 2px;
}
.btn-outline-primary:hover{
    background-color: var(--accent-turquoise);
    border-color: var(--accent-turquoise);

}


.demoCode{
    padding: 12px;
    background-color: var(--background-dark);
    margin-top: 5px;
    font-family: var(--standard-font);


}
.demoCode .col-md-12{
    padding: var(--interface-padding);

}

.demoCode p{
    color: #F7BC00;

    /* Desktop/Code/Code */

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */

}

.codeLinks a{
    color: var(--accent-turquoise);
}

.demoCode p code a{
    color: #fff;
}


.listVideo{
    border: 1px solid var(--accent-blue-gray);
}



.cta p{
    font-family: var(--standard-font);
    line-height: 120%;

}

.cta h3 {
    margin: 0px;
    margin-bottom: 20px;
}
.cta p a{
    color: var(--accent-blue-gray);
}

/* end code output */

/* footer */

footer{
    background-color: var(--background-dark);
    padding-top: 30px;
    margin-top: 90px;
    padding-bottom: 50px;
}

.loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 5px solid;
    border-color: rgba(255, 255, 255, 0.15) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-right: 12px;
    top: 2px;

}

.betaMessage p small{
    color: var(--background-extra-light)
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* bootstrap overrides */
.rounded{
    border-radius: 7px !important;
}
.container-fluid {
    --bs-gutter-x: 0rem;
}

/** media queries **/

/** large displays **/
@media screen and (min-width: 992px) {

}


/** tablets and up **/
@media screen and (min-width: 992px) {
    .sampleImage {
        flex: 0 0 auto;
        width: 10%;
    }
}

::backdrop {
    opacity: 0.8;
    background: var(--Greys-Black, #0F1011);
}

.modal-window {
    margin: auto;
    max-width: 700px;
    margin-top: 50px;
    border-radius: 20px;
    border: 2px solid var(--0-d-9-affaccentblue, #0D9AFF);
    background: white;
}

.close-parent {
    text-align: right;
    justify-content: flex-end;
}

.close-parent button {
    border: 0;
    color: transparent;
    background: transparent;
    transition: 0.3s;
}

.close-parent button:hover {
    cursor: pointer;
    transform: scale(1.25);
}

.feedback-parent {
    padding: var(--interface-padding);
}
.feedback-btn {
    display: inline-flex;
    height: 32px;
    min-width: 64px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 32px;
    border: 1px solid var(--primary-sky-blue-sky-blue-60, #0D9AFF);
    background: transparent;
    color: #0D9AFF;
    max-width: 170px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 14.4px */
    text-transform: uppercase;
    transition: 0.3s;
    padding: 0 20px;
    margin-left: 40px;
    margin-bottom: 40px;
}

.feedback-btn:hover {
    color: white;
    border-color: white;
}

.link-parent {
    display: flex;
    justify-content: space-between;
}

.link-parent a{
    color: var(--0-d-9-affaccentblue, #0D9AFF);
    /* Links/In-text Link on Dark */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    text-decoration-line: underline;
}

.sampleImage {
    transition: 0.3s;
    cursor: pointer;
    padding: 5px;
}
.sampleImage:hover {
    transform: scale(1.08);
}

#arSelect:hover {
    cursor: pointer;
}




/*Navigation*/
#alert-bar {
  position: relative;
  background: #3F5FFF;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 35px;
}
#alert-bar .widget p {
  margin: 0;
  line-height: 1.4;
}
#alert-bar .widget a {
  color: inherit;
  text-decoration: none;
}
#alert-bar .widget .btn {
  position: relative;
  border: solid 1px #FFF;
  text-transform: none;
  padding: 4px 15px;
  margin-left: 10px;
  margin-top: -2px;
  font-size: 14px;
}
#alert-bar #alert-close {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}

#header {
  /* header-frame */
}
#header a {
  font-family: "Inter", sans-serif;
}
#header #header-inner {
  transition: all 0.3s linear;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  font-size: 15px;
  line-height: 1.65;
}
#header #header-inner .header-frame {
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  padding-top: 5px;
}
#header #header-inner .header-frame .container-fluid {
  display: flex;
  align-items: center;
  max-width: 1360px;
  flex-wrap: nowrap;
  margin: auto;
  padding: 0 40px;
}
#header.fixed-position #header-inner .header-frame {
  position: fixed;
}
#header .header-frame {
  padding: 0;
}
#header .header-frame .navbar-default {
  background: none;
  border: 0;
  margin: 0;
  min-height: 1px;
}
#header .header-frame .navbar-default .navbar-collapse {
  padding: 0;
  flex: 1 1 auto;
}
#header .header-frame .navbar-default .navbar-collapse .header-section {
  display: flex;
  justify-content: space-between;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav {
  position: static;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  margin: 0;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li {
  padding: 0 10px;
  position: static;
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  list-style-type: none;
  white-space: nowrap;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #0C163B;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  padding: 24px 0;
  text-transform: uppercase;
  position: relative;
  display: block;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #ffffff;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open {
  background: none;
  font-weight: 500;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a:active, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a:focus, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a:active, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a:focus, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a:active, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a:focus {
  background: none;
  font-weight: 500;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner .dropdown-column {
  flex: 1 0 36%;
  max-width: calc(36% - 20px);
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner .dropdown-column:nth-child(2) > .link-list {
  padding-top: 48px;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(2) .dropdown-menu .inner .dropdown-column {
  flex: 1 0 50%;
  max-width: calc(50% - 20px);
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(5) .dropdown-menu .inner .dropdown-column {
  flex: 1 0 50%;
  max-width: 47%;
}
#header .header-frame .navbar-default .navbar-collapse .signup-block {
  margin-left: 20px;
}
#header .header-frame .navbar-default .navbar-collapse .signup-block .btn {
  font-size: 14px;
  line-height: 1.4;
  padding: 8px 18px;
  border-radius: 20px;
  height: auto;
  margin-top: 0;
  border: none;
  white-space: nowrap !important;
}
#header .header-frame .navbar-default .navbar-collapse .signup-block .btn:hover {
  background: #D6364B;
  color: #ffffff;
}
#header .header-frame .header-side-nav {
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#header .header-frame .header-side-nav .header-side-nav-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#header .header-frame .header-side-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
#header .header-frame .header-side-nav ul.sub-menu {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  z-index: 100;
  background-color: #ffffff;
  padding: 0;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.05);
}
#header .header-frame .header-side-nav ul.sub-menu li {
  display: block;
  text-align: left;
  padding: 0;
  margin: 0;
}
#header .header-frame .header-side-nav ul.sub-menu li:before {
  display: none;
}
#header .header-frame .header-side-nav ul.sub-menu li a {
  padding: 15px 25px;
  display: block;
}
#header .header-frame .header-side-nav ul.sub-menu li a:hover {
  background-color: #0C163B;
  color: #ffffff;
}
#header .header-frame .header-side-nav li:not(.btn) {
  padding: 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
#header .header-frame .header-side-nav li:not(.btn) a {
  color: #0C163B;
  font-weight: 400;
  padding: 2px 7px;
  font-size: 13px;
  font-family: sofia-pro, sans-serif;
}
#header .header-frame .header-side-nav li:not(.btn).has-icon > a {
  display: flex;
  min-width: 60px;
}
#header .header-frame .header-side-nav li:not(.btn).has-icon > a .nav-icon {
  flex: 0 0 18px;
  max-width: 18px;
  max-height: 18px;
  margin-right: 5px;
  flex-shrink: 0;
}
#header .header-frame .header-side-nav li:not(.btn).has-icon > a span {
  flex-shrink: 0;
}
#header .header-frame .header-side-nav li:not(.btn).has-icon.hide-title .nav-icon {
  margin-right: 0;
}
#header .header-frame .header-side-nav li:not(.btn):not(.has-icon) {
  padding-top: 2px;
}
#header .header-frame .header-side-nav li:not(.btn).hide-title > a {
  min-width: 1px;
}
#header .header-frame .header-side-nav li:not(.btn).hide-title > a span {
  display: none;
}
#header .header-frame .header-side-nav li:not(.btn):hover a {
  text-decoration: none;
}
#header .header-frame .header-side-nav li:not(.btn):hover .sub-menu {
  display: block;
}
#header .header-frame .header-side-nav li:not(.btn):before {
  content: "";
  display: block;
  width: 1px;
  height: 15px;
  background-color: #CCC;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -7.5px;
}
#header .header-frame .header-side-nav li:not(.btn) .nav-icon {
  width: 18px;
}
#header .header-frame .header-side-nav li:not(.btn) .nav-icon svg {
  width: 100%;
}
#header .header-frame .header-side-nav li:not(.btn).dropdown .dropdown-menu {
  border-top: none;
  border-bottom: none;
  margin: 0;
  min-width: 160px;
}
#header .header-frame .header-side-nav li:not(.btn).dropdown .dropdown-menu .inner {
  flex-direction: column;
  padding: 10px 0;
  gap: 0;
}
#header .header-frame .header-side-nav li:not(.btn).dropdown .dropdown-menu .inner .dropdown-column {
  padding: 0;
}
#header .header-frame .header-side-nav li:not(.btn).dropdown .dropdown-menu .inner .dropdown-column .dropdown-item {
  padding: 5px 10px;
  border: none;
  font-size: 15px;
  line-height: 1;
  color: #0C163B;
}
#header .header-frame .header-side-nav li:not(.btn).dropdown .dropdown-menu .inner .dropdown-column .dropdown-item:hover {
  box-shadow: none;
  color: #3F5FFF;
}
#header .header-frame .header-side-nav li.btn {
  padding: 0;
}
#header .header-frame .header-side-nav li.btn a {
  color: inherit;
  padding: 8px 18px;
  font-size: 14px;
  line-height: 1.4;
  display: block;
  min-width: 128px;
}
#header .header-frame .header-side-nav .btn:hover {
  color: inherit;
}

.dropdown-menu {
  font-family: "Inter", sans-serif;
  padding: 0;
  border: none;
  min-width: 100%;
  max-width: 100%;
  box-shadow: none;
  border-top: solid 1px #EEEEF1;
  border-bottom: solid 1px #EEEEF1;
  border-radius: 0;
}
.dropdown-menu .inner {
  align-items: stretch !important;
  gap: 20px;
}
.dropdown-menu .inner::before, .dropdown-menu .inner::after {
  display: none;
}
.dropdown-menu .inner .dropdown-column {
  padding: 30px 0;
  min-width: 22.25%;
}
.dropdown-menu .inner .dropdown-column .dropdown-header {
  padding: 10px 0;
  margin: 0 0 5px 0;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  color: #0B173B;
}
.dropdown-menu .inner .dropdown-column .dropdown-item {
  display: block;
  padding: 20px 24px;
  font-size: 15px;
  line-height: 1.2;
  color: #0C163B;
  text-decoration: none;
  border-left: solid 3px transparent;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-weight: 600;
  text-transform: none;
}
.dropdown-menu .inner .dropdown-column .dropdown-item .sub {
  display: block;
  padding-top: 5px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #49506c;
  text-transform: none;
}
.dropdown-menu .inner .dropdown-column .dropdown-item.hide-title {
  display: none;
  visibility: hidden;
}
.dropdown-menu .inner .dropdown-column .dropdown-item:hover {
  border-color: #F7BC02;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.05);
}
.dropdown-menu .inner .dropdown-column .link-list {
  padding: 0;
  list-style: none;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item {
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item .nav-icon {
  flex: 1 0 34px;
  max-width: 34px;
  height: 34px;
  margin-right: 10px;
  background: rgb(22, 36, 54);
  background: linear-gradient(170deg, rgb(22, 36, 54) 40%, rgb(42, 62, 88) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#162436", endColorstr="#2a3e58",GradientType=1 );
  border-radius: 4px;
  padding: 7px;
  padding: 0;
  text-align: center;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item .nav-icon svg {
  max-width: 100%;
  max-height: 100%;
  filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(254deg) brightness(110%) contrast(110%);
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item .nav-icon img {
  width: 100%;
  max-width: 20px;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item .nav-icon + .dropdown-header {
  display: block;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1;
  color: #0C163B;
  font-weight: 400;
  text-transform: none;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item a {
  display: block;
  padding: 10px 0;
  font-size: 18px;
  line-height: 1;
  color: #0C163B;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item a .chevron {
  display: inline-block;
  margin-left: 8px;
  transition: opacity 0.3s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z' fill='%230C163B'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
  transform: translateY(2px);
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item.has-description a .sub {
  display: block;
  padding-top: 5px;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #0C163B;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item:not(.heading):hover .nav-icon {
  background: #3F5FFF;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item:not(.heading):hover a {
  text-decoration: none;
  color: #3F5FFF;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item:not(.heading):hover a .sub {
  color: #3F5FFF;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list-item:hover .chevron, .dropdown-menu .inner .dropdown-column .link-list .link-list-item:focus .chevron {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z' fill='%233F5FFF'/%3E%3C/svg%3E");
}
.dropdown-menu .inner .dropdown-column .link-list .link-list {
  padding-left: 44px;
  margin-top: -5px;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item {
  position: relative;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item a {
  padding: 5px 0;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 60px;
  background-color: #707070;
  position: absolute;
  top: -5px;
  left: -27px;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item::after {
  content: "";
  display: inline-block;
  width: 17px;
  height: 1px;
  background-color: #707070;
  position: absolute;
  top: 50%;
  left: -27px;
  transform: translateY(-50%);
}
.dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item:first-child::before {
  top: 5px;
}
.dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item:last-child::before {
  height: 31px;
}
.dropdown-menu .inner .dropdown-column .sdk-list {
  list-style: none;
  padding: 0 24px;
  margin: 10px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.dropdown-menu .inner .dropdown-column .sdk-list li {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 2%;
  min-height: 62px;
  display: flex;
}
.dropdown-menu .inner .dropdown-column .sdk-list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.dropdown-menu .inner .dropdown-column .sdk-list li a img {
  transition: opacity 0.2s ease-in-out;
}
.dropdown-menu .inner .dropdown-column .sdk-list li a span {
  font-size: 10px;
  color: #0C163B;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
  padding: 0 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.dropdown-menu .inner .dropdown-column .sdk-list li a:hover {
  background-color: #f1f2f9;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
}
.dropdown-menu .inner .dropdown-column .sdk-list li a:hover img {
  opacity: 0;
}
.dropdown-menu .inner .dropdown-column .sdk-list li a:hover span {
  opacity: 1;
}
.dropdown-menu .inner .dropdown-column .sdk-list li:nth-child(5n+5) {
  margin-right: 0;
}
.dropdown-menu .inner .description {
  display: flex;
  gap: 6%;
}
.dropdown-menu .inner .description ul {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown-menu .inner .description ul a {
  display: block;
  padding: 10px 0;
  font-size: 16px;
  line-height: 1;
  color: #0C163B;
  font-weight: 400;
  text-transform: none;
  margin:0;
}
.dropdown-menu .inner .description ul a::after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z' fill='%233F5FFF'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
  transform: translateY(2px);
}
.dropdown-menu .inner .description ul a:hover, .dropdown-menu .inner .description ul a:focus {
  color: #3F5FFF;
  font-weight: 500;
}
.dropdown-menu .inner .description ul a:hover::after, .dropdown-menu .inner .description ul a:focus::after {
  opacity: 1;
}
.dropdown-menu .inner .description ul + div {
  margin-top: 28px;
}
.dropdown-menu .inner .featured-content {
  flex: 1 0 52%;
  max-width: 52%;
  background-color: #F3F4FE;
  padding: 30px 45px;
}
.dropdown-menu .inner .featured-content .title {
  display: block;
  padding: 10px 0;
  margin: 0 0 10px 0;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  color: #0B173B;
}

.dropdown-menu .nav-col .col-header, .dropdown-menu .nav-col .dropdown-item.title {
  font-family: "Inter", sans-serif;
  letter-spacing: 0;
  font-size: 15px;
}

.social-networks {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
}
.social-networks li {
  display: inline-block;
  vertical-align: top;
  padding: 0 4px;
  font-size: 15px;
}

.search-block {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 0 5px;
  margin-right: 5px;
  text-align: left;
  max-height: 28px;
  line-height: 1;
  position: relative;
}
.search-block .search-opener {
  border: 0;
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  color: #0C163B;
}
.search-block .search-opener svg {
  width: 14px;
  margin-top: 1px;
  margin-right: 4px;
}

.search_box {
  display: none;
  width: 320px;
  text-shadow: none;
  position: absolute;
  z-index: 101;
  top: 100%;
  right: 0;
  margin-right: -12px;
  margin-top: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.search_box.active {
  display: inline-block;
}
.search_box > div {
  padding: 10px;
  background-color: #ffffff;
  position: relative;
}
.search_box > div:after {
  bottom: 100%;
  right: 15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 5px;
  margin-left: -5px;
}
.search_box .gsc-control-cse {
  padding: 0;
  background: none;
  border: none;
}
.search_box form.gsc-search-box {
  margin-bottom: 0;
}
.search_box table.gsc-search-box {
  margin-bottom: 0;
}
.search_box .gsc-input {
  padding-right: 0;
}
.search_box .gsc-input-box {
  height: 30px;
  overflow: hidden;
}
.search_box input.gsc-input {
  font-size: 14px;
  padding: 6px !important;
}
.search_box button.gsc-search-button {
  height: 23px;
  min-width: 7px;
  padding: 4px 15px 3px 15px;
  margin: 0;
  border-radius: 0;
  border-color: #3F5FFF;
  background-color: #3F5FFF;
}
.search_box .gsib_a {
  padding: 2px 6px 3px;
}
.search_box * {
  box-sizing: content-box;
}
.search_box .gsc-tabsArea {
  font-size: 14px;
}
.search_box .gsc-wrapper .gsc-adBlock {
  display: none !important;
}
.search_box .gsc-wrapper .gs-title {
  color: #3F5FFF;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}
.search_box .gsc-wrapper .gs-title b {
  color: #3F5FFF;
  font-weight: 600;
}
.search_box .gsc-wrapper .gs-visibleUrl {
  color: #999;
  margin: 5px 0 15px 0;
}
.search_box .gsc-wrapper .gs-snippet {
  font-size: 14px;
  line-height: 1.4;
}
.search_box .gsc-wrapper .gs-per-result-labels {
  display: none;
}
.search_box .gsc-wrapper .gsc-table-result {
  direction: rtl;
}
.search_box .gsc-wrapper .gsc-table-result td {
  padding-left: 8px;
}
.search_box .gsc-wrapper .gsc-results > .gsc-result {
  border-bottom: solid 1px #f8f8f8;
  padding: 20px 0;
}
.search_box .gsc-wrapper .gsc-expansionArea > .gsc-result {
  border-bottom: solid 1px #f8f8f8;
  padding: 20px 0;
}

@media screen and (max-width: 1470px) {
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1400px) {
  #header .header-frame .header-side-nav {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 10px;
    margin-top: 5px;
    position: relative;
  }
  #header .header-frame .navbar-default .navbar-collapse .signup-block {
    margin-left: 0;
    margin-top: 7px;
  }
  #header .header-frame .navbar-default .navbar-collapse .header-section {
    align-items: flex-end;
  }
}
@media screen and (max-width: 1249px) {
  #header .header-frame .header-side-nav li a {
    padding: 2px 4px;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li {
    padding: 0 6px;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a {
    font-size: 12px !important;
  }
  #header .header-frame .header-side-nav {
    font-size: 12px;
  }
  #header .header-frame .header-side-nav li.btn a, #header .header-frame .navbar-default .navbar-collapse .signup-block .btn {
    font-size: 12px;
    min-width: 1px;
  }
  .search-block {
    margin-right: 10px;
  }
  #header .header-frame .navbar-default .navbar-collapse .signup-block {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1070px) {
  .navbar-toggle {
    display: block;
    border: none;
    padding: 0;
  }
  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background: none;
  }
  .navbar-collapse.collapse:not([aria-expanded=true]) {
    display: none !important;
    overflow: hidden !important;
  }
  .navbar-toggler-icon {
    display: block;
    padding: 5px 5px 0 5px;
  }
  #nav-icon {
    width: 22px;
    height: 22px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: inline-block;
  }
  #nav-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  .navbar-toggler-icon:not(.js-replace-color) #nav-icon span {
    background: #0C163B;
  }
  #nav-icon span:nth-child(1) {
    top: 0px;
  }
  #nav-icon span:nth-child(2), #nav-icon span:nth-child(3) {
    top: 10px;
  }
  #nav-icon span:nth-child(4) {
    top: 20px;
  }
  #nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  #nav-icon.open span:nth-child(2) {
    transform: rotate(45deg);
  }
  #nav-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  #nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  #header #header-inner .header-frame .navbar > .container-fluid {
    flex-direction: column;
    align-items: stretch;
  }
  #header #header-inner .header-frame .navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #header #header-inner .header-frame .navbar-header:before, #header #header-inner .header-frame .navbar-header:after {
    display: none;
  }
  #header #header-inner .header-frame .navbar-header .logo {
    margin-left: 15px;
  }
  #header .header-frame .navbar-default .navbar-collapse {
    padding-left: 15px;
    padding-right: 15px;
  }
  #header .header-frame .navbar-default .navbar-collapse.in {
    max-height: calc(100vh - 50px);
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav {
    padding: 0;
    float: none;
    text-align: center;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li {
    margin: 0;
    display: inline-block;
    float: none;
    padding: 0;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a {
    display: block;
    padding: 15px;
    text-align: center;
  }
  .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    background: none;
  }
  #header .header-frame .header-side-nav li a.show-sub + ul.sub-menu {
    display: block;
  }
  .dropdown-menu .inner {
    padding-left: 30px;
    padding-right: 30px;
  }
  .dropdown-menu .inner .dropdown-column .dropdown-header {
    font-size: 15px;
    color: #304B6F;
  }
  .dropdown-menu .inner .dropdown-column .link-list .link-list-item.has-description a {
    font-size: 15px;
    text-transform: none;
  }
  .dropdown-menu .inner .description ul a {
    font-size: 15px;
  }
  .dropdown-menu .inner .dropdown-column .link-list .link-list-item.has-description a .sub {
    font-size: 12px;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner {
    flex-wrap: wrap;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner .dropdown-column {
    flex: 1 0 40%;
    max-width: calc(40% - 20px);
  }
}
@media screen and (max-width: 991px) {
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li {
    display: block;
  }
  #mainMenu .dropdown-menu {
    padding-left: 30px;
    padding-right: 30px;
  }
  .dropdown-menu .inner .dropdown-column, .dropdown-menu .inner .dropdown-column:nth-child(2) {
    flex: 1 1 100% !important;
    max-width: 100% !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  .dropdown-menu .inner .dropdown-column .link-list .link-list-item.has-description a {
    padding: 15px 0;
  }
  .dropdown-menu .inner .featured-content .description {
    padding: 0;
  }
  .search_box {
    right: auto;
    margin-right: 0;
    left: 0;
    margin-left: -25px;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a::after {
    background-color: #3F5FFF;
  }
  #header .header-frame .navbar-default .navbar-collapse .header-section {
    flex-direction: column;
    align-items: stretch;
  }
  #header .header-frame .header-side-nav {
    padding-bottom: 40px;
    padding-top: 20px;
    border-top: solid 1px #EEEEF1;
  }
  #header .header-frame .navbar-default .navbar-collapse .signup-block {
    margin-top: 20px;
    margin-left: 0;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav {
    justify-content: center;
    position: relative;
  }
  #header .header-frame .header-side-nav .menu {
    position: relative;
  }
  #header .header-frame .header-side-nav li.dropdown {
    position: static;
  }
  #header .header-frame .header-side-nav li:not(.btn):first-child::before {
    display: none;
  }
  .dropdown-menu .inner {
    padding-left: 0;
    padding-right: 0;
  }
  .dropdown-menu .inner .description {
    flex-direction: column;
  }
  .dropdown-menu .inner .featured-content {
    flex: 1 0 100%;
    max-width: 150%;
    margin-left: -21px;
    margin-right: -21px;
    padding: 30px;
  }
  #header .header-frame .header-side-nav {
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 10px;
    margin-top: 0;
    position: relative;
  }
  .search-block {
    display: none;
    visibility: hidden;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner .dropdown-column:nth-child(1) {
    padding-bottom: 0;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner .dropdown-column:nth-child(2) {
    padding-top: 0;
    margin-top: -20px;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner .dropdown-column:nth-child(2) > .link-list {
    padding-top: 0;
  }
  .dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item a {
    padding: 5px 0;
  }
  .dropdown-menu .inner .dropdown-column .link-list .link-list .link-list-item:last-child::before {
    height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .dropdown-menu .inner {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
  .dropdown-menu .inner .dropdown-column + .dropdown-column {
    padding-top: 0;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav {
    flex-direction: column;
  }
  #header .header-frame .header-side-nav li:not(.btn).dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
  #mainMenu .dropdown-menu {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover .dropdown-menu, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active .dropdown-menu {
    display: block;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a:active::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a:focus::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a:active::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a:focus::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a:active::after, #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a:focus::after {
    background-color: #3F5FFF;
  }
  #header .header-frame .navbar-default .navbar-collapse .menu > li:hover .dropdown-menu, #header .header-frame .navbar-default .navbar-collapse .menu > li.active .dropdown-menu {
    display: block;
  }
}
@media screen and (min-width: 1071px) {
  .navbar-toggle {
    display: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a.dropdown-toggle {
    pointer-events: none;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(4) .dropdown-menu .inner .dropdown-column:nth-child(1) {
    flex: 0 0 16%;
    max-width: calc(16% - 20px);
    min-width: 1px;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(4) .dropdown-menu .inner .dropdown-column:nth-child(2) {
    flex: 0 0 32%;
    max-width: calc(32% - 20px);
  }
  .d-lg-flex {
    width: 100%;
    display: flex;
  }
  .dropdown-menu .inner .description .d-lg-flex ul + div {
    margin-top: -40px;
    flex: 1;
  }
}
/** video api nav spacing **/
.nav-featured a {
  padding: 5px 24px 10px 24px !important;
}

@media (min-width: 1350px) {
  #header .navbar-header .logo {
    margin-bottom: 5px;
  }
  #header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a {
    padding: 32px 0 24px 0;
  }
}

#header .header-frame .navbar-default {
    padding: 0px;
}

.dropdown-menu {
    position: absolute!important;
    width: 100%;
    top: 62px;
    left: 0;
}
.dropdown-menu .inner .description ul a {
    text-decoration: none;
}

#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a:after {
    border: 0;
}

#header .header-frame .header-side-nav  li#menu-item-16518:not(.btn):before {
    display: none;
}

#header .header-frame .navbar-default .navbar-collapse .signup-block .btn {
    font-size: 14px;
    line-height: 1.4;
    padding: 8px 18px;
    border-radius: 20px;
    height: auto;
    margin-top: 0;
    border: none;
    white-space: nowrap!important;
    background-color: #3f5fff;
    border-color: #3f5fff;
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
}

#header .header-frame .navbar-default .navbar-collapse .signup-block .btn:hover {
    background-color: #2640C2 !important;
}

#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:nth-child(1) .dropdown-menu .inner .dropdown-column:nth-child(2) > .link-list {
    padding-top: 0;
}

#header .header-frame .header-side-nav li:not(.btn) a {
    text-transform: none;
}



#header #header-inner .header-frame {
  background-color: #0E1A27;
  box-shadow: none;
}
#header .logo .cls-1 {
  fill: #FFF !important;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a {
  color: #FFF;
  /* padding: 12px 0; */
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li > a::after {
  background-color: #0E1A27;
  height: 1px;
}
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a:active::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.active > a:focus::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a:active::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li.open > a:focus::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a:active::after,
#header .header-frame .navbar-default .navbar-collapse .navbar-nav > li:hover > a:focus::after {
  background-color: #FFF;
}
#header .header-frame #top-right-menu > li > a,
.search-block .search-opener {
  color: #FFF;
  text-decoration: none;
}
#header .header-frame #top-right-menu > li > a > .nav-icon {
  filter: brightness(0) invert(1);
}

#header .header-frame .navbar-default .navbar-collapse .signup-block .btn:hover {
  background-color: #2640C2 !important;
}

@media (max-width: 1399px) {
  #header .header-frame .header-side-nav {
    margin-top: 0;
  }
}
@media (max-width: 1070px) {
  #header .navbar-toggler-icon #nav-icon span {
    background-color: #FFF;
  }
  #header.fixed-position #header-inner .header-frame {
    position: fixed;
    padding-top: 10px;
    padding-right: 20px;
  }
  #header #header-inner .header-frame .navbar-header {
    height: 40px;
    padding-bottom: 20px;
  }
  button.navbar-toggle.collapsed {
    background: none;
  }

  #header .menu-item.open .dropdown-menu {
    display: block;
    position: fixed!important;
    top: 91px;
  }

}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    background-color: #FFF;
  }

  #header.fixed-position #header-inner .header-frame {
    position: fixed;
    padding-top: 10px;
    padding-right: 20px;
  }
  #header #header-inner .header-frame .navbar-header {
    height: 40px;
    padding-bottom: 20px;
  }
  button.navbar-toggle.collapsed {
    background: none;
  }
}



#footer {
  font-family: "Inter", sans-serif;
  background-color: #0C163B;
  color: #B3CFFB;
  padding-top: 75px;
  font-weight: 500;
  font-size: 14px;
}
#footer a {
  color: #B3CFFB;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0;
}
#footer a:hover {
  color: #d3d3d3;
}
#footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#footer ul li {
  margin-bottom: 5px;
}
#footer .footer-seals li {
  display: block;
}
#footer .social-networks {
  margin-top: 15px;
}
#footer .title {
  display: block;
  margin-bottom: 10px;
  line-height: 28px;
  font-size: 16px;
  color: #FFF;
}
#footer .widgets {
  display: flex;
  justify-content: space-between;
}
#footer .widgets .col {
  padding: 0 15px;
  margin-bottom: 30px;
}
#footer .footer-holder {
  padding-bottom: 60px;
}
#footer .footer-bot {
  padding: 40px 0;
  background-color: #050D2C;
  color: #B3CFFB;
  font-size: 14px;
}
#footer .footer-bot p {
  margin-bottom: 0;
}
#footer .footer-bot a {
  color: #B3CFFB;
}
#footer .footer-bot a:hover {
  color: #efefef;
}
#footer .footer-bot ul li {
  display: inline-block;
  margin-left: 15px;
}
#footer .footer-bot ul.footer-links {
  text-align: right;
}
#footer .footer-bot .copyright {
  text-align: right;
}

/* Responsive
 -------------------------------------------------------------- */
@media screen and (max-width: 1070px) {
  #footer .widgets {
    flex-wrap: wrap;
  }
  #footer .widgets .col {
    max-width: 25%;
    flex: 1 1 25%;
  }
  #footer .widgets .col:nth-child(5) {
    margin-left: 25%;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer-bot ul.footer-links, #footer .footer-bot .copyright {
    text-align: center;
  }
  #footer .footer-bot ul li {
    margin: 0 7.5px;
  }
  .footer-seals {
    margin-bottom: 20px;
  }
  .footer-seals ul {
    text-align: center;
  }
}
@media screen and (max-width: 655px) {
  #footer .widgets .col {
    max-width: 50%;
    flex: 1 1 50%;
  }
  #footer .widgets .col:nth-child(1) {
    max-width: 100%;
    flex: 1 1 100%;
  }
  #footer .widgets .col:nth-child(5) {
    margin-left: 0;
  }
}
@media screen and (min-width: 1070px) {
    #footer {
        padding-bottom: 0;
    }
    .footer-bot, .footer-holder {
        padding: 40px!important;
    }
}

.dropdown-menu {
	display: none;
	background: white;
	top: 75px;
}
#header {width: 100vw;}

@mixin button-reset {
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
}




#footer {
  font-family: "Inter", sans-serif;
  background-color: #0C163B;
  color: #B3CFFB;
  padding-top: 75px;
  font-weight: 500;
  font-size: 14px;
}
#footer a {
  color: #B3CFFB;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0;
}
#footer a:hover {
  color: #d3d3d3;
}
#footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#footer ul li {
  margin-bottom: 5px;
}
#footer .footer-seals li {
  display: block;
}
#footer .social-networks {
  margin-top: 15px;
}
#footer .title {
  display: block;
  margin-bottom: 10px;
  line-height: 28px;
  font-size: 16px;
  color: #FFF;
}
#footer .widgets {
  display: flex;
  justify-content: space-between;
}
#footer .widgets .col {
  padding: 0 15px;
  margin-bottom: 30px;
}
#footer .footer-holder {
  padding-bottom: 60px;
}
#footer .footer-bot {
  padding: 40px 0;
  background-color: #050D2C;
  color: #B3CFFB;
  font-size: 14px;
}
#footer .footer-bot p {
  margin-bottom: 0;
}
#footer .footer-bot a {
  color: #B3CFFB;
}
#footer .footer-bot a:hover {
  color: #efefef;
}
#footer .footer-bot ul li {
  display: inline-block;
  margin-left: 15px;
}
#footer .footer-bot ul.footer-links {
  text-align: right;
}
#footer .footer-bot .copyright {
  text-align: right;
}

/* Responsive
 -------------------------------------------------------------- */
@media screen and (max-width: 1070px) {
  #footer .widgets {
    flex-wrap: wrap;
  }
  #footer .widgets .col {
    max-width: 25%;
    flex: 1 1 25%;
  }
  #footer .widgets .col:nth-child(5) {
    margin-left: 25%;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer-bot ul.footer-links, #footer .footer-bot .copyright {
    text-align: center;
  }
  #footer .footer-bot ul li {
    margin: 0 7.5px;
  }
  .footer-seals {
    margin-bottom: 20px;
  }
  .footer-seals ul {
    text-align: center;
  }
}
@media screen and (max-width: 655px) {
  #footer .widgets .col {
    max-width: 50%;
    flex: 1 1 50%;
  }
  #footer .widgets .col:nth-child(1) {
    max-width: 100%;
    flex: 1 1 100%;
  }
  #footer .widgets .col:nth-child(5) {
    margin-left: 0;
  }
}
@media screen and (min-width: 1070px) {
    #footer {
        padding-bottom: 0;
    }
    .footer-bot, .footer-holder {
        padding: 40px!important;
    }
}

#footer {
    width: 100%;
    margin-top: 0;
}

.hero .tag {
  color: white!important;
}

.hero .tag a {
  color: #48C4D8!important;
  text-decoration: none;
}



.grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    max-width: 1215px !important;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 60px;
}
.col-sm-12.col-md-6.col-xl-4.mb-4.demoListing {
    width: 100%;
}
article h3 {
    padding-bottom: 10px;
    display: block;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
}
article {
    background-color: #101926;
    border-radius: 20px;
    padding: 20px;
}
video.listVideo {
    width: 100%;
    aspect-ratio: 1.5;
    object-fit: cover;
    max-height: 200px;
}
.other-title {
    font-size: 30px;
    font-family: "Inter";
    max-width: 1215px !important;
    margin: 25px auto -30px auto !important;
    padding-left: 20px;
    padding-top: 50px;
    color: white;
    font-weight: 500;
}

@media (max-width: 1200px) {
  .grid {
    display: grid;
    grid-template-columns: auto auto!important;
  }
}
@media (max-width: 800px) {
  .grid {
    display: grid;
    grid-template-columns: auto!important;
  }
}