@import '~/src/utils/_variables.scss';

.page-container.home-page {
  text-align: center;
  .title {
    font-size: 32px;
    font-weight: 700;
  }
  .subtitle {
    font-size: 20px;
    font-weight: 400;
  }
  .pages-links {
    margin-top: 100px;
    display: flex;
    .links-column {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 30px;
      }
      .page-nav-button {
        margin-top: auto;
        box-shadow: 0px 2px 10px 0px #3448c51a;
        border-radius: 8px;
        cursor: pointer;
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.85;
        }
        &:not(:last-child) {
          margin-bottom: 30px;
          margin-top: 0;
        }
      }
    }
  }
  @media ($mq-mobile) {
    .pages-links {
      .links-column {
        .page-nav-button {
          margin-top: 0;
        }
      }
    }
  }
}
