@import '~/src/utils/_variables.scss';

.photo-select-container {
  margin-left: auto;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      font-weight: 700;
    }
    .subtitle {
      font-size: 13px;
      line-height: 14px;
      font-weight: 400;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -15px;
        top: 2px;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        border: 2px solid $greenBright;
        box-sizing: border-box;
      }
    }
  }
  .current-asset {
    min-height: 180px;
    width: 100%;
    border-radius: 8px;
    background-color: rgb(98, 150, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    .point-selection {
      position: absolute;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      border: 2px solid $greenBright;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  .asset-select {
    display: flex;
    .upload-button {
      height: 54px;
      width: 54px;
      padding: 0;
      flex-shrink: 0;
      background-color: rgba($greyBlue, 0.5);
      border-radius: 8px;
      margin: 4px 5px 0 0;
    }
    .scroller-container {
      overflow: hidden;
      position: relative;
      padding: 4px 0;
      &::before,
      &::after {
        content: '';
        height: 64px;
        width: 6px;
        position: absolute;
        top: 0;
        z-index: 9;
      }
      &::before {
        left: 0;
        background-image: linear-gradient(to right, $white, transparent);
      }
      &::after {
        right: 0;
        background-color: $grey;
        width: 1px;
      }
      .scroller {
        display: flex;
        overflow-x: scroll;
        padding-bottom: 5px;
        position: relative;
        .asset-option {
          width: 95px;
          height: 54px;
          overflow: hidden;
          flex-shrink: 0;
          margin: 0 5px;
          border-radius: 8px;
          background-color: rgb(98, 150, 255);
          box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1);
          &.selected {
            outline: 5px solid $primary;
            outline-offset: -5px;
          }
        }
      }
    }
  }
  @media ($mq-mobile) {
    width: auto;
    margin-left: 0;
  }
}
