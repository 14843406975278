@import '~/src/utils/_variables.scss';

.page-container.player-engagement-page {
  .bottom-section {
    padding-top: 20px;
  }
  @media ($mq-mobile) {
    .top-section {
      .controls-section {
        .controls {
          flex-direction: column;
          align-items: flex-start;
          .toggle-switch-container {
            margin-bottom: 10px;
          }
        }
      }
    }
    .bottom-section {
      width: 100%;
    }
  }
}
