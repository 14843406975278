@import '~/src/utils/_variables.scss';

.page-container.automated-video-page {
	background: var(--Gradients-Stormy-Sky, linear-gradient(150deg, #0c163b 15.12%, #18246f 56.41%, #6f447e 94.45%));
	width: 100%;
	max-width: 100%!important;
	padding: 55px 30px !important;

	.hero {
	    width: 100%;
	    max-width: 1265px;
	    margin: auto;
	    padding: 30px;
	    box-sizing: border-box;
	}
	.asset-select .upload-button {
	  width: 100px;
	  height: auto;
	  background: transparent;
	  margin: 0;
	  margin-bottom: 4px;
	  margin-left: 5px;
	}

	.range-slider-container .value {
		color: white;
	}
	.links {
		padding: 0;
		padding-top: 20px;
		margin-bottom: 40px;
	}
	.range-slider-container .slider .MuiSlider-rail {
		border-radius: 2px;
		border: 0.5px solid rgba(0, 0, 0, 0.12);
		background: #484848;
		box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05) inset;
		height: 4px;
	}
	.top-section {
	    border-bottom: 0;
	    padding-bottom: 20px;
	}
	.loading-asset {
		height: 100%!important;
	}
	.top-section:after {
	    content: "Results are generated in real time (can take up to few minutes).";
	    display: block;
	    width: 100%;
	    color: white;
	    width: 100%;
	    margin: auto;
	    padding: 20px 0px 10px 0;
	    background: #101926;
	    box-sizing: border-box;
	    color: var(--Greys-30, #d5dde6);
	    font-family: Inter;
	    font-size: 16px;
	    font-style: normal;
	    font-weight: 300;
	    line-height: 150%;
	    max-width: 1215px;
	}
	.links {
	    color: #FFF;
	    font-family: Inter;
	    font-size: 18px;
	    font-style: normal;
	    font-weight: 400;
	    line-height: 150%;
	    margin-bottom: 0!important;
	    padding-bottom: 30px!important;
	}
	.video-select-container {
		width: 100%!important;
		display: flex;
		flex-direction: row-reverse;
	    justify-content: space-between;
	}
	.top-section .controls-section {
		margin-top: 0!important;
	}
	.range-slider-container {
		margin-left: 0;
	}
	.histogram-container .label {
		font-weight: 300;
	}

	.histogram-container {
		display: block!important;
	}

	span.MuiSlider-track.css-ttgsjq-MuiSlider-track {
		color: rgba(72, 196, 216, 1);
		height: 4px;
	}
	.range-slider-container .label {
		color: var(--Greys-30, #D5DDE6);
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%; /* 24px */
	}
	.video-select-container {
    	width: 490px;
	}

	.video-select-container .asset-select .scroller-container .scroller .asset-option.selected {
		outline: 2px solid #48c4d8;
	    outline: 2px solid var(--48-c-4-d-8-accentturquoise, #48c4d8);
	    outline-offset: -2px;
	}
	.video-select-container .asset-select .scroller-container .scroller .asset-option {
		transition: 0.3s;
	}
	.video-select-container .asset-select .scroller-container .scroller .asset-option:hover, .upload-button:hover {
	  transform: scale(1.1);
	}
	.video-select-container .asset-select .scroller-container .scroller {
	    overflow: auto;
	    height: 100px;
	    align-items: center;
	}

	.video-select-container .asset-select .scroller-container .scroller .asset-option {
	    height: 70px;
    	width: 85px;
	}
	.video-select-container .asset-select .scroller-container .scroller .asset-option img {
		height: 100%;
	    object-fit: cover;
	    width: auto;
	}
	.video-select-container .asset-select .scroller-container::before, .video-select-container .asset-select .scroller-container::after {
		display: none;
	}

	.top-section .page-info, #current-asset, .histogram-container, .header, .controls-section .title {
		display: none;
	}

	.top-section .controls-section {
		margin-top: -100px;
	}
	.video-select-container {
		margin-right: auto;
		margin-left: 0;
	}
	.bottom-section {
	    max-width: 1215px;
    	margin: auto;
    	background-color: #101926;
    	padding: 20px;
	}
	.extra {
	    background-color: #101926;
	    color: #fff;
	    color: #d5dde6;
	    color: var(--Greys-30, #d5dde6);
	    font-family: Inter;
	    font-size: 16px;
	    font-style: normal;
	    font-weight: 300;
	    line-height: 150%;
	    padding: 10px 0px 10px 0px;
	}
	.docs-parent {
	  padding: 30px 0px;
	  margin-bottom: 60px;
	  background-color: rgba(16, 25, 38, 1);
	  color: white;
	  font-family: "Roboto Mono";
	  font-size: 16px;
	  font-style: normal;
	  font-weight: 400;
	  line-height: 150%; /* 24px */
	}
	.docs-parent p:nth-of-type(2) {margin-bottom: 30px;}
	.docs-parent p {
	   word-break:break-all;
	}
	.orange {
	  color: #F7BC00;
	}

	.top-section .controls-section .controls {
		margin-left: 0;
		margin-top: 20px;
	}

	.bottom-section {
	padding-top: 20px;
	box-sizing: border-box;
    width: 92%;
	}

	.vid-parent {
	    width: 100%;
	    display: grid;
	    grid-template-columns: 1fr 1fr;
	    gap: 20px;
	}

	@media (max-width: 1320px) {
		.hero {
			width: 92%;
			box-sizing: content-box;
		}
	}

	@media (max-width: 850px) {
		.top-section .controls-section {
	        height: 50px;
	        align-items: flex-start;
	    }
	    .vid-parent {
	    	grid-template-columns: 1fr;
	    }
		.video-select-container {
			flex-direction: column-reverse;
		}
		.top-section {
			width: 100%;
		}
		.histogram-container {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.top-section .controls-section {
			margin-top: 15px!important;
		}
	}

	.range-ticks {
	    display: none !important;
	}
	.vid-parent p {
		text-align: center;
		color: white;
		font-family: "Inter";
		font-size: 16px;
		margin-bottom: 10px;
	}
	.docs-parent a {
		color: white;
		text-decoration: none;
	}
	.docs-parent a:hover {
		text-decoration: underline;
	}
}
