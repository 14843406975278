@import '~/src/utils/_variables.scss';

.loading-asset {
  background-color: #fff;
  display: flex;
  height: 540px;
  width: 100%;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  .loading-text {
    margin-left: 10px;
    line-height: 16px;
  }
}
