@import '~/src/utils/_variables.scss';

.page-container.ai-cropping-page {
  background: var(--Gradients-Stormy-Sky, linear-gradient(150deg, #0c163b 15.12%, #18246f 56.41%, #6f447e 94.45%));
  width: 100%;
  max-width: 100%;
  padding: 55px 30px !important;
  max-width: 100%!important;
    width: 100%!important;

  .hero {
      width: 100%;
      max-width: 1015px!important;
      margin: auto;
      padding: 30px 0;
  }
  .asset-select .upload-button {
    width: 110px;
    height: auto;
    background: transparent;
    margin: 0;
  }
  .toggle-parent {
    position: relative;
  }
  .toggle-parent p {
    color: var(--Greys-30, #D5DDE6);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 300px;
  }


  .device-container:nth-of-type(1):after {
      content: "Smart Cropped";
      color: white;
      font-size: 16px;
      font-family: 'Inter';
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translate(-50%, 0%);
      white-space: nowrap;
  }

  .device-container:nth-of-type(2):after {
      content: "Original";
      color: white;
      font-size: 16px;
      font-family: 'Inter';
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translate(-50%, 0%);
      white-space: nowrap;
  }


  .multi-photo-select-container .asset-select .scroller-container .scroller .asset-option {
      margin: 0;
      border-radius: 8px;
      /* box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1); */
      /* overflow: hidden; */
      display: grid;
      margin-right: 10px;  }

  .hero, .top-section, .bottom-section {
    max-width: 1215px;
    margin: auto;
  }
  .hero {
    max-width: 1165px!important;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px;
    padding-right: 20px;
  }

.select-container {
    margin-left: 0px;
    margin-top: 30px;
    margin-right: 20px;
}

.range-slider-container {
  padding: 0!important;
}

.bottom-section {
  min-height: 500px;
  max-width: 1215px!important;
  background-color: black;
}

.btn.primary {
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    height: 24px;
    padding: 0 12px;
    display: inline-flex;
    height: 32px;
    min-width: 64px;
    padding: 16px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    background: transparent !important;
    border: 1px solid white !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    margin-top: 25px;
}

.range-slider-container {
  margin: 0;
}

.MuiInputBase-root {
  height: 50px;
}

.image-to-video-page .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  padding-right: 20px;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
    opacity: 1;
    border: 0 !important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 30px;
    display: flex;
    background: #363D45;
    padding-right: 20px;
}

  .css-aqb3a8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    opacity: 1;
    border: 0!important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 50px;
    display: flex;
    background: #363D45;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    height: 54px;
    color: white;
  }

  .top-section {
    background-color: #101926;
    padding: 10px 30px 0px 30px;
  }

  .range-slider-container .slider .MuiSlider-rail {
    border-radius: 2px;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    background: #484848;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05) inset;
    height: 4px;
  }

  span.MuiSlider-track.css-ttgsjq-MuiSlider-track {
    color: rgba(72, 196, 216, 1);
    height: 4px;
  }
  .range-slider-container .label {
    color: var(--Greys-30, #D5DDE6);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .video-select-container {
      width: 490px;
  }
  .bottom-section .apps .app-container.hide {display: none;}

  .video-select-container .asset-select .scroller-container {
    width: auto;
}

  .scroller-container .scroller .asset-option.selected {
    outline: 2px solid #48c4d8;
      outline: 2px solid var(--48-c-4-d-8-accentturquoise, #48c4d8);
      outline-offset: -2px;
  }
  .scroller-container .scroller .asset-option {
    transition: 0.3s;
  }
  .scroller-container .scroller .asset-option:hover, .upload-button:hover {
    transform: scale(1.1);
  }
  .scroller-container .scroller {
      overflow: auto;
      height: 100px;
      align-items: center;
  }

  .scroller-container .scroller .asset-option {
      height: 70px!important;
      width: 70px!important;
  }
  .scroller-container .scroller .asset-option img {
    height: 100%;
      object-fit: cover;
      width: auto;
  }

  .multi-photo-select-container .asset-select .scroller-container .scroller .selected {
      border: 0;
  }
  .scroller-container::before, .scroller-container::after {
    display: none;
  }

  .top-section .page-info, #current-asset, .histogram-container, .header, .controls-section .title {
    display: none;
  }

  .top-section {
    border-bottom: 0;
  }

  .top-section .controls-section {
    margin-top: -100px;
  }
  .video-select-container {
    margin-right: auto;
    margin-left: 0;
  }
  .bottom-section {
      max-width: 965px;
      margin: auto;
      background-color: black;
      padding: 30px;
  }
  .extra {
      background-color: #101926;
      color: #fff;
      color: #d5dde6;
      color: var(--Greys-30, #d5dde6);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      padding: 10px 0px 10px 0px;
  }
  .docs-parent {
    padding: 30px 0px;
    margin-bottom: 60px;
    background-color: rgba(16, 25, 38, 1);
    color: white;
    font-family: "Roboto Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .docs-parent p:nth-of-type(2) {margin-bottom: 30px;}
  .docs-parent p {
     word-break:break-all;
  }

  .links {
    color: white;
    padding: 30px 30px 40px 30px;
    margin-bottom: 60px;
  }
  .links a {
    color: #48C4D8;
  }
  .docs-parent {
    margin-bottom: 0!important;
  }

  .orange {
    color: #F7BC00;
  }

.multi-photo-select-container .asset-select .scroller-container {
  overflow: visible;
}
.multi-photo-select-container .asset-select .scroller-container .scroller {
     height: auto; 
    display: flex;
    flex-wrap: nowrap;
    overflow-y: overlay;
    position: relative;
    align-content: flex-start;
    flex-direction: row-reverse;
    overflow: visible;
}
.app-header {display: none;}
.range-slider-container {
  display: grid;
  grid-template-areas:
          "title title"
          "left right";
  padding: 10px;
}
.label {
    grid-area: title;
}

.range-slider-container .slider {
  margin: 0;
  margin-right: 5px;
}
.range-slider-container .value {
  color: white;
  margin-left: 5px;
}


.multi-photo-select-container {
  margin-left: 0;
  margin-right: auto;
}

.top-section, .bottom-section, .links, .hero, .extra, .docs-parent, .hero {
  box-sizing: border-box;
  width: 92%;
  max-width: 1215px !important;
}

.bottom-section .apps .app-container .devices {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.upload-button {z-index: 1;}

  .controls-section .controls .range-slider-container .slider {
    width: 95px;
  }
  .bottom-section {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    video {
      width: auto !important;
    }
  }

  .docs-parent a {
    color: white;
    text-decoration: none;
  }
  .docs-parent a:hover {
    color: white;
    text-decoration: underline;
  }
  .docs-parent, .extra, .links{
    max-width: 1215px !important;
    margin: auto;
    padding: 20px 30px 20px 30px;
    background-color: #101926;
  }

  .links {
    padding-bottom: 40px!important;
  }
  .horizontal .no-cropping {
    margin-top: 50px!important;
  }
  .top:after {
      content: "Results are generated in real time (can take up to few minutes).";
      display: block;
      width: 100%;
      color: white;
      width: 92%;
      margin: auto;
      padding: 15px 30px;
      background: #101926;
      box-sizing: border-box;
      color: var(--Greys-30, #d5dde6);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      max-width: 1215px;
  }

  .links {
      padding-bottom: 40px !important;
      color: #FFF;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
  }

  .bottom-section {
    padding-top: 70px;
    .toggles {
      width: 40%;
      color: black;
        div {
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
          width: 301px;
          height: 42px;
          flex-shrink: 0;
          border-radius: 40px;
          background: var(--Primary-Aegean-Blue-50, #A3B7D5);
          align-items: center;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          cursor: pointer;        
        }
        div.active {
          background: var(--primary-turquoise-blue-turquoise-60, #48C4D8);
        }
      }
    .apps {
      width: 60%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .app-container {
        width: 100%!important;
        display: block;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        .devices {
          display: flex;
          .device-container {
            background-color: rgba(#000, 0.9);
            height: 384px;
            width: 192px;
            margin: 0 20px;
            position: relative;
            border-radius: 30px;
            .screen-image {
              width: 198px;
              top: -8px;
              left: -2px;
              max-width: none;
              position: absolute;
              z-index: 1;
              pointer-events: none;
            }
            .video-container {
              border-radius: 30px;
              overflow: hidden;
              height: 100%;
              &.facebook .inner-container {
                margin-top: auto;
                transform: translateY(50%);
              }
              .spinner-container {
                background-color: rgba(22, 22, 22, 0.9);
                height: 100%;
                display: flex;
                align-self: center;
                justify-content: center;
                .MuiBox-root {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
        .hide {
          display: none;
        }
        .app-header {
          position: absolute;
          top: -80px;
          left: 50%;
          transform: translateX(-50%);
          height: 50px;
          width: 50px;
          margin: 0 auto 24px;
          &.text {
            font-size: 13px;
            font-weight: 700;
            width: auto;
            line-height: 50px;
            white-space: nowrap;
          }
        }
        &.horizontal {
          padding-left: 20px;
          .device-container {
            width: 384px;
            height: 192px;
            .screen-image {
              height: 198px;
              width: 384px;
              top: -2px;
              left: 0;              
            }
          }
        }
      }
      &.withoutCropping {
        justify-content: center;
        .app-container {
          width: 50%;
          &.horizontal {
            .device-container {
              margin: 0 0 30px 0;
              &.no-cropping {
                .text {
                  top: -60px;
                }
              }
            }
            .devices {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  @media ($mq-mobile) {
    .bottom-section {
      .apps {
        flex-direction: column;
        align-items: center;
        .app-container {
          width: 90%;
          margin-bottom: 30px;
          .devices {
            flex-direction: column;
            .device-container {
              margin-bottom: 20px;
              width: 150px;
              height: 300px;
              &.no-cropping {
                background-color: red;
                margin-top: 50px;
                .text {
                  top: -60px;
                }
              }
              .screen-image {
                height: 300px;
                width: 150px;
                top: 0;
                left: 0;
              }
            }
          }
          &.horizontal {
            .device-container {
              width: 300px;
              height: 150px;
              .screen-image {
                height: 150px;
                width: 300px;
              }
              &.no-cropping {
                margin-top: 0;
              }
            }
          }
        }
        &.withoutCropping {
          .app-container {
            &.horizontal {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .top, .docs-parent, .extra {
      width: 92%;
      box-sizing: border-box;
    }
    .top-section {
      width: 100%;
    }
    .top:after {
      width: auto;
    }
    .toggle-parent p {
      position: relative;
      top: -20px;
    }
    .bottom-section .apps.withoutCropping {
      width: 100%!important;
      justify-content: center;
      margin-top: 30px;
    }
    .bottom-section .apps .app-container .devices {
      align-items: center; 
    }
  }

  @media (max-width: 600px) {
    .bottom-section {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
    }
    .top-section {
      width: 100%;
    }
    .bottom-section .apps .app-container .devices {
      flex-direction: column-reverse;
    }          
    .toggles {
      width: 100%!important;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .bottom-section .apps.withoutCropping .app-container.horizontal .devices {
    flex-direction: column-reverse;
  }
  .bottom-section .apps.withoutCropping .app-container.horizontal .device-container {
    margin-top: 10px!important;
    margin-bottom: 80px!important;
  }

}







