$mq-desktop: '(min-width: 877px)';
$mq-mobile: '(max-width: 876px)';

$black: #000;
$white: #fff;
$primary: #3549c5;
$grey: #cdcdcd;
$greyLight: #fafafc;
$greyBlue: #d7dee8;
$greenBright: #00ff19;
$purple: #5028f3;
$purpleLight: #dcd4fd;
$red: #eb5757;
$redLight: #ffd5c8;



.grid {
	display: grid;
    grid-template: 1 1 1;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    max-width: 1215px !important;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 60px;
}
.col-sm-12.col-md-6.col-xl-4.mb-4.demoListing {
    width: 100%;
}
article h3 {
    padding-bottom: 10px;
    display: block;
    font-family: 'Inter';
}
article {
    background-color: #101926;
    border-radius: 20px;
    padding: 20px;
}