@import '~/src/utils/_variables.scss';

.page-container.video-transformations-page {

  background: var(--Gradients-Stormy-Sky, linear-gradient(150deg, #0c163b 15.12%, #18246f 56.41%, #6f447e 94.45%));
  width: 100%;
  max-width: 100%;
  padding: 55px 30px !important;
  max-width: 100%!important;
    width: 100%!important;

  .hero {
      width: 100%;
      max-width: 1215px!important;
      margin: auto;
      padding: 30px 0;
  }
  .asset-select .upload-button {
    height: auto;
    background: transparent;
    margin: 0;
    width: 100px;
    margin-bottom: 4px;
    margin-left: 5px;
  }
  .toggle-parent {
    position: relative;
  }
  .toggle-parent p {
    color: var(--Greys-30, #D5DDE6);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 300px;
  }
  .multi-photo-select-container .asset-select .scroller-container .scroller .asset-option {
      margin: 0;
      border-radius: 8px;
      /* box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1); */
      /* overflow: hidden; */
      display: grid;
      margin-right: 10px;  }

  .hero, .top-section, .bottom-section {
    max-width: 1215px;
    margin: auto;
  }
  .hero {
    max-width: 1265px!important;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px;
    padding-right: 20px;
  }

.select-container {
    margin-left: 0px;
    margin-top: 30px;
    margin-right: 20px;
}

.range-slider-container {
  padding: 0!important;
}

.bottom-section {
  max-width: 1215px!important;
  background-color: black;
}

.btn.primary {
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    height: 24px;
    padding: 0 12px;
    display: inline-flex;
    height: 32px;
    min-width: 64px;
    padding: 16px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    background: transparent !important;
    border: 1px solid white !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    margin-top: 25px;
}

.range-slider-container {
  margin: 0;
}

.MuiInputBase-root {
  height: 50px;
}

.image-to-video-page .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  padding-right: 20px;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
    opacity: 1;
    border: 0 !important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 30px;
    display: flex;
    background: #363D45;
    padding-right: 20px;
}

  .css-aqb3a8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    opacity: 1;
    border: 0!important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 50px;
    display: flex;
    background: #363D45;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    height: 54px;
    color: white;
  }

  .top-section {
    background-color: #101926;
    padding: 10px 30px 0px 30px;
  }

  .range-slider-container .slider .MuiSlider-rail {
    border-radius: 2px;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    background: #484848;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05) inset;
    height: 4px;
  }

  .docs-parent, .extra, .links{
    max-width: 1215px !important;
    margin: auto;
    padding: 30px!important;
    background-color: #101926;
  }

  .links {
    padding-bottom: 40px!important;
  }

  span.MuiSlider-track.css-ttgsjq-MuiSlider-track {
    color: rgba(72, 196, 216, 1);
    height: 4px;
  }
  .range-slider-container .label {
    color: var(--Greys-30, #D5DDE6);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .video-select-container {
      width: 490px;
  }
  .bottom-section .apps .app-container.hide {display: none;}

  .video-select-container .asset-select .scroller-container {
    width: auto;
}

  .scroller-container .scroller .asset-option.selected {
    outline: 2px solid #48c4d8;
      outline: 2px solid var(--48-c-4-d-8-accentturquoise, #48c4d8);
      outline-offset: -2px;
  }
  .scroller-container .scroller .asset-option {
    transition: 0.3s;
  }
  .scroller-container .scroller .asset-option:hover, .upload-button:hover {
    transform: scale(1.1);
  }
  .scroller-container .scroller {
      overflow: auto;
      height: 100px;
      align-items: center;
  }

  .scroller-container .scroller .asset-option {
      height: 70px!important;
      width: 70px!important;
  }
  .scroller-container .scroller .asset-option img {
    height: 100%;
      object-fit: cover;
      width: auto;
  }

  .multi-photo-select-container .asset-select .scroller-container .scroller .selected {
      border: 0;
  }
  .scroller-container::before, .scroller-container::after {
    display: none;
  }

  .top-section .page-info, #current-asset, .histogram-container, .header, .controls-section .title {
    display: none;
  }

  .controls-section {
    margin-top: 0;
  }
  .controls {
    color: white;
  }

  .video-select-container {
    margin-right: auto;
    margin-left: 0;
  }
  .bottom-section {
      max-width: 965px;
      margin: auto;
      background-color: black;
      padding: 30px;
      width: 100%;
      display: grid!important;
      grid-template-columns: 40% 60%;
  }
  .profile-bar {
    grid-column: span 2;
  }
  .extra {
      background-color: #101926;
      color: #fff;
      color: #d5dde6;
      color: var(--Greys-30, #d5dde6);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      padding: 10px 0px 10px 0px;
  }
  .docs-parent {
    padding: 30px 0px;
    margin-bottom: 60px;
    background-color: rgba(16, 25, 38, 1);
    color: white;
    font-family: "Roboto Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .docs-parent p:nth-of-type(2) {margin-bottom: 30px;}
  .docs-parent p {
     word-break:break-all;
  }
  .orange {
    color: #F7BC00;
  }

.multi-photo-select-container .asset-select .scroller-container {
  overflow: visible;
}
.multi-photo-select-container .asset-select .scroller-container .scroller {
     height: auto; 
    display: flex;
    flex-wrap: nowrap;
    overflow-y: overlay;
    position: relative;
    align-content: flex-start;
    flex-direction: row-reverse;
    overflow: visible;
}
.app-header {display: none;}
.range-slider-container {
  display: grid;
  grid-template-areas:
          "title title"
          "left right";
  padding: 10px;
}
.label {
    grid-area: title;
}

.range-slider-container .slider {
  margin: 0;
  margin-right: 5px;
}
.range-slider-container .value {
  color: white;
  margin-left: 5px;
}


.multi-photo-select-container {
  margin-left: 0;
  margin-right: auto;
}



  .controls-section .controls .range-slider-container .slider {
    width: 95px;
  }
  .bottom-section {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    direction: rtl;
    video {
      width: auto !important;
    }
  }

  .bottom-section .top-section {
      padding-top: 0 !important;
      background-color: black !important;
  }
  .controls-section .profile, .profile-bar {
    display: none!important;
  }

  .MuiLinearProgress-colorPrimary {
      background-color: #304B6F!important;
  }

  .MuiLinearProgress-bar {
    background-color: #48C4D8!important;
  }

  .bottom-section video {
    width: 100%!important;
  }
  .controls-section .controls .control-container {
    width: calc(100% - 60px)!important;
    background-color: black!important;
    direction: ltr;
    margin-bottom: 0!important;
    box-shadow: none!important;
  }
  .controls-section .controls {
    margin-top: 0!important;
    padding-top: 0!important;
  }
  .controls-section .controls .control-container:nth-child(even) {
    margin: 0!important;
  }
  .controls-section .controls .control-container .control {
    margin: auto!important;
    margin-right: 0 !important;
  }
  .controls-section .actions {
    justify-content: center;
    margin: 0!important;
    align-items: baseline;
    order: 2;
  }
  .controls-section{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap!important;
  }      
  .toggle-switch-container .switch input:checked + .slider {
    background-color: #48C4D8!important;
  }

  .controls-section .actions .btn.secondary {
    color: #48C4D8!important;
  }
  


  .top-section {
    border-bottom: none;
  }

  .top-section, .bottom-section, .links, .hero, .extra, .docs-parent {
    max-width: 1215px!important;
    box-sizing: border-box;
    width: 92%;
  }



  .top-section {
      border-bottom: 0;
      padding-bottom: 20px;
  }
  .top-section:after {
      content: "Results are generated in real time (can take up to few minutes).";
      display: block;
      width: 100%;
      color: white;
      width: 100%;
      margin: auto;
      padding: 10px 0px 10px 0;
      background: #101926;
      box-sizing: border-box;
      color: var(--Greys-30, #d5dde6);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      max-width: 1215px;
  }
  .links {
      color: #FFF;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0!important;
      padding-bottom: 30px!important;
  }
  .control-container:nth-of-type(4), .control-container:nth-of-type(5) {
      display: none !important; 
  }

    .controls-section {
      flex-wrap: wrap;
      .actions {
        margin-left: auto;
        display: flex;
        .btn {
          margin-left: 10px;
        }
      }
      .controls {
        flex-wrap: wrap;
        margin: 20px 0;
        flex-basis: 100%;
        border-top: 1px solid rgba($black, 0.25);
        padding-top: 20px;
        .control-container {
          padding: 0 14px;
          height: 42px;
          background-color: $white;
          width: 46%;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1);
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          &:nth-child(even) {
            margin-left: auto;
          }
          .toggle-switch-container {
            margin-left: 0;
          }
          .control {
            margin-left: auto;
            .range-slider-container {
              margin-left: 0;
              .label {
                display: none;
              }
              .slider {
                margin: 0 0 0 0;
                order: 2;
              }
              .value {
                order: 1;
                text-align: end;
                margin-right: 14px;
              }
            }
            .dimensions {
              display: flex;
              .input-container {
                position: relative;
                .text-field-container {
                  margin-left: 10px;
                  width: 84px;
                }
                .dimensions-unit {
                  position: absolute;
                  right: 4px;
                  top: 0;
                  line-height: 24px;
                  opacity: 0.5;
                  font-size: 13px;
                }
              }
            }
            .rotation {
              display: flex;
              .rotate-button {
                height: 28px;
                width: 28px;
                margin-right: 12px;
                svg {
                  height: 20px;
                }
              }
              .reset-button {
                color: $primary;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }


  @media ($mq-mobile) {
    .top-section {
      .controls-section {
        .controls {
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: flex-start;
          .control-container {
            width: 90%;
            &:nth-child(even) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  @media (max-width: 900px) {
    .top, .docs-parent, .extra {
      width: 92%;
      box-sizing: border-box;
    }
    .bottom-section {
        display: flex !important;
        flex-direction: column;
    }
    .controls {
      display: flex;
      justify-content: center;
    }
    .controls-section .controls .control-container {
      width: 100%!important;
      padding: 0!important;
    }
    .toggle-parent p {
      position: relative;
      top: -20px;
    }
    .bottom-section .apps.withoutCropping {
      width: 100%!important;
      justify-content: center;
      margin-top: 30px;
    }
    .bottom-section .apps .app-container .devices {
      align-items: center; 
    }

    .top-section .controls-section {
      position: relative;
      top: 0;
      width: 100%;
      right: 0;
    }
    .top-section {
      padding: 0!important;
    }
    .profile .profile-title { 
      margin-right: 20px!important;
    }

    .profile-bar {
        position: relative;
        width: 100%;
        left: 0;
        margin: 0 !important;
        margin-bottom: 30px !important;
    }

    video {
      margin-top: 40px!important;
    }

    .scroller-container {
      overflow: auto;
    }
    .video-select-container {
      width: auto!important;
    }
    .video-transformations-page {
      box-sizing: border-box;
    }
    .top-section:after {
      width: calc(100% - 40px);
      padding: 0px 0px 20px 0;
    }
    .bottom-section {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 600px) {
    .top-section {
      overflow: hidden;
    }
    .toggles {
      width: 100%!important;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
