@import '~/src/utils/_variables.scss';

.page-container.smart-integrations-page {



  background: var(--Gradients-Stormy-Sky, linear-gradient(150deg, #0c163b 15.12%, #18246f 56.41%, #6f447e 94.45%));
  width: 100%;
  max-width: 100%;
  padding: 55px 30px !important;
  max-width: 100%!important;
    width: 100%!important;

  .hero {
      width: 100%;
      max-width: 1015px!important;
      margin: auto;
      padding: 30px 0;
  }
  .asset-select .upload-button {
    width: 110px;
    height: auto;
    background: transparent;
    margin: 0;
  }
  .toggle-parent {
    position: relative;
  }
  .toggle-parent p {
    color: var(--Greys-30, #D5DDE6);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 300px;
  }
  .multi-photo-select-container .asset-select .scroller-container .scroller .asset-option {
      margin: 0;
      border-radius: 8px;
      /* box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1); */
      /* overflow: hidden; */
      display: grid;
      margin-right: 10px;  }

  .hero, .top-section, .bottom-section {
    max-width: 1115px;
    margin: auto;
  }
  .hero {
    max-width: 1165px!important;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px;
    padding-right: 20px;
  }

.select-container {
    margin-left: 0px;
    margin-top: 30px;
    margin-right: 20px;
}

.range-slider-container {
  padding: 0!important;
}

.bottom-section {
  max-width: 1115px!important;
  background-color: black;
}

.btn.primary {
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    height: 24px;
    padding: 0 12px;
    display: inline-flex;
    height: 32px;
    min-width: 64px;
    padding: 16px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    background: transparent !important;
    border: 1px solid white !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    margin-top: 25px;
}

.range-slider-container {
  margin: 0;
}

.MuiInputBase-root {
  height: 50px;
}

.image-to-video-page .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  padding-right: 20px;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
    opacity: 1;
    border: 0 !important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 30px;
    display: flex;
    background: #363D45;
    padding-right: 20px;
}

  .css-aqb3a8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    opacity: 1;
    border: 0!important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 50px;
    display: flex;
    background: #363D45;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    height: 54px;
    color: white;
  }

  .top-section {
    background-color: #101926;
    padding: 10px 30px 0px 30px;
  }

  .range-slider-container .slider .MuiSlider-rail {
    border-radius: 2px;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    background: #484848;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05) inset;
    height: 4px;
  }

  .docs-parent, .extra, .links{
    max-width: 1115px !important;
    margin: auto;
    padding: 30px!important;
    background-color: #101926;
  }

  .links {
    padding-bottom: 40px!important;
  }

  span.MuiSlider-track.css-ttgsjq-MuiSlider-track {
    color: rgba(72, 196, 216, 1);
    height: 4px;
  }
  .range-slider-container .label {
    color: var(--Greys-30, #D5DDE6);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .video-select-container {
      width: 490px;
  }
  .bottom-section .apps .app-container.hide {display: none;}

  .video-select-container .asset-select .scroller-container {
    width: auto;
}

  .scroller-container .scroller .asset-option.selected {
    outline: 2px solid #48c4d8;
      outline: 2px solid var(--48-c-4-d-8-accentturquoise, #48c4d8);
      outline-offset: -2px;
  }
  .scroller-container .scroller .asset-option {
    transition: 0.3s;
  }
  .scroller-container .scroller .asset-option:hover, .upload-button:hover {
    transform: scale(1.1);
  }
  .scroller-container .scroller {
      overflow: auto;
      height: 100px;
      align-items: center;
  }

  .scroller-container .scroller .asset-option {
      height: 70px!important;
      width: 70px!important;
  }
  .scroller-container .scroller .asset-option img {
    height: 100%;
      object-fit: cover;
      width: auto;
  }

  .multi-photo-select-container .asset-select .scroller-container .scroller .selected {
      border: 0;
  }
  .scroller-container::before, .scroller-container::after {
    display: none;
  }

  .top-section .page-info, #current-asset, .histogram-container, .header, .controls-section .title {
    display: none;
  }

  .controls-section {
    margin-top: 0;
  }
  .controls {
    color: white;
  }

  .video-select-container {
    margin-right: auto;
    margin-left: 0;
  }
  .bottom-section {
      max-width: 965px;
      margin: auto;
      background-color: black;
      padding: 30px;
      width: 100%;
      display: grid!important;
      grid-template-columns: 40% 60%;
  }
  .profile-bar {
    grid-column: span 2;
  }
  .extra {
      background-color: #101926;
      color: #fff;
      color: #d5dde6;
      color: var(--Greys-30, #d5dde6);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      padding: 10px 0px 10px 0px;
  }
  .docs-parent {
    padding: 30px 0px;
    margin-bottom: 60px;
    background-color: rgba(16, 25, 38, 1);
    color: white;
    font-family: "Roboto Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .docs-parent p:nth-of-type(2) {margin-bottom: 30px;}
  .docs-parent p {
     word-break:break-all;
  }
  .orange {
    color: #F7BC00;
  }

.multi-photo-select-container .asset-select .scroller-container {
  overflow: visible;
}
.multi-photo-select-container .asset-select .scroller-container .scroller {
     height: auto; 
    display: flex;
    flex-wrap: nowrap;
    overflow-y: overlay;
    position: relative;
    align-content: flex-start;
    flex-direction: row-reverse;
    overflow: visible;
}
.app-header {display: none;}
.range-slider-container {
  display: grid;
  grid-template-areas:
          "title title"
          "left right";
  padding: 10px;
}
.label {
    grid-area: title;
}

.range-slider-container .slider {
  margin: 0;
  margin-right: 5px;
}
.range-slider-container .value {
  color: white;
  margin-left: 5px;
}


.multi-photo-select-container {
  margin-left: 0;
  margin-right: auto;
}



  .controls-section .controls .range-slider-container .slider {
    width: 95px;
  }
  .bottom-section {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    direction: rtl;
    video {
      width: auto !important;
    }
  }

  .bottom-section .top-section {
      padding-top: 0 !important;
      background-color: black !important;
  }
  .controls-section .profile, .profile-bar {
    display: none!important;
  }

  .MuiLinearProgress-colorPrimary {
      background-color: #304B6F!important;
  }

  .MuiLinearProgress-bar {
    background-color: #48C4D8!important;
  }





  .top-section {
    border-bottom: none;
  }
  .controls-title {
    margin-bottom: 19px;
    display: none;
  }
  .bottom-section {
    border-top: 1px solid rgba($black, 0.25);
    padding-top: 20px;
    display: flex;
    .player-container {
      flex-basis: 60%;
    }
    .controls-section {
      flex-basis: 40%;
      padding-left: 20px;
      box-sizing: border-box;
      .controls {
        .control-container {
          padding: 25px 0;
          &:not(:last-child) {
            border-bottom: 1px solid rgba($black, 0.25);
          }
          .header {
            display: flex;
            justify-content: space-between;
            .control-label {
              font-weight: 700;
              font-size: 13px;
              margin-bottom: 30px;
            }
          }
          .items {
            display: flex;
            flex-wrap: wrap;
            &.tags,
            &.moderation {
              .item {
                height: 24px;
                border-radius: 8px;
                box-sizing: border-box;
                padding: 4px 10px;
                margin: 8px 8px 0 0;
                font-size: 12px;
                font-weight: 600;
                cursor: pointer;
              }
            }
            &.tags .item {
              color: $purple;
              background-color: $purpleLight;
            }
            &.moderation .item {
              color: $red;
              background-color: $redLight;
            }
            &.transcript {
              flex-direction: column;
              max-height: 90px;
              overflow-y: auto;
              flex-wrap: nowrap;
              .item {
                display: flex;
                font-size: 13px;
                font-weight: 400;
                margin-bottom: 18px;
                .time {
                  margin-right: 24px;
                }
              }
            }
          }
        }
        .spinner-container {
          display: flex;
          align-items: center;
          justify-content: end;
          .text {
            margin-left: 12px;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
    }
  }
  @media ($mq-mobile) {
    .bottom-section {
      flex-direction: column;
    }
  }

  .bottom-section .controls-section .controls .control-container .items.tags .item {
      background-color: #425A7A;
      color: white;
  }

}
