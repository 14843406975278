@import '~/src/utils/_variables.scss';

.page-container.video-interactivity-page {
  .top-section {
    border-bottom: none;
    .controls-section {
      flex-wrap: wrap;
      margin-bottom: 20px;
      .controls {
        margin: 20px 0;
        flex-basis: 100%;
        border-top: 1px solid rgba($black, 0.25);
        padding-top: 20px;
        .control-container {
          padding: 20px 14px;
          background-color: $white;
          width: 33%;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1);
          &:not(:last-child) {
            margin-right: 16px;
          }
          .toggle-switch-container {
            margin-left: 0;
          }
          .fields {
            margin-top: 21px;
            display: flex;
            padding-left: 44px;
            &.hide {
              display: none;
            }
            .select-container {
              margin-left: 0;
            }
          }
        }
      }
      .apply-button {
        margin: 0 auto;
        height: 32px;
        padding: 0 40px;
        font-size: 18px;
      }
    }
  }
  @media ($mq-mobile) {
    .top-section {
      .controls-section .controls {
        flex-wrap: wrap;
        .control-container {
          min-width: 160px;
          margin-bottom: 10px;
        }
      }
    }
    .player-container {
      width: 100%;
    }
  }
}
