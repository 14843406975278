@import '~/src/utils/_variables.scss';


.page-container.video-optimization-page>.top-section:after {
    content: "Results are generated in real time (can take up to few minutes).";
    display: block;
    width: 100%;
    color: white;
    width: 100%;
    margin: auto;
    padding: 0px 0px 15px 0px;
    background: #101926;
    box-sizing: border-box;
    color: var(--Greys-30, #d5dde6);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    max-width: 1215px;
}

.page-container.video-optimization-page {
  background: var(--Gradients-Stormy-Sky, linear-gradient(150deg, #0c163b 15.12%, #18246f 56.41%, #6f447e 94.45%));
  width: 100%;
  max-width: 100%;
  padding: 55px 30px !important;
  max-width: 100%!important;
    width: 100%!important;

  .hero {
      width: 100%;
      max-width: 1215px!important;
      margin: auto;
      padding: 30px 0;
  }
  .asset-select .upload-button {
    width: 100px;
    height: auto;
    background: transparent;
    margin: 0;
    margin-left: 5px;
    margin-bottom: 4px;
  }
  .docs-parent a {
    color: white;
    text-decoration: none;
  }
  .docs-parent a:hover {
    color: white;
    text-decoration: underline;
  }
  .toggle-parent {
    position: relative;
  }
  .toggle-parent p {
    color: var(--Greys-30, #D5DDE6);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 300px;
  }
  .multi-photo-select-container .asset-select .scroller-container .scroller .asset-option {
      margin: 0;
      border-radius: 8px;
      /* box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1); */
      /* overflow: hidden; */
      display: grid;
      margin-right: 10px;  }

  .hero, .top-section, .bottom-section {
    max-width: 1215px;
    margin: auto;
  }
  .hero {
    max-width: 1215px!important;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px;
    padding-right: 20px;
  }

.select-container {
    margin-left: 0px;
    margin-top: 30px;
    margin-right: 20px;
}

.range-slider-container {
  padding: 0!important;
}

.bottom-section {
  min-height: 500px;
  max-width: 1215px!important;
  background-color: black;
}

.btn.primary {
    color: #fff;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    height: 24px;
    padding: 0 12px;
    display: inline-flex;
    height: 32px;
    min-width: 64px;
    padding: 16px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    background: transparent !important;
    border: 1px solid white !important;
    border-radius: 20px !important;
    opacity: 1 !important;
    margin-top: 25px;
}

.range-slider-container {
  margin: 0;
}

.MuiInputBase-root {
  height: 50px;
}

.image-to-video-page .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  padding-right: 20px;
}

.download-button {
    display: none;
} 
.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
    opacity: 1;
    border: 0 !important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 30px;
    display: flex;
    background: #363D45;
    padding-right: 20px;
}

  .css-aqb3a8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    opacity: 1;
    border: 0!important;
    padding: 5px 10px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: white;
    height: 50px;
    display: flex;
    background: #363D45;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    height: 54px;
    color: white;
  }

  .top-section {
    background-color: #101926;
    padding: 10px 30px 0px 30px;
  }

  .range-slider-container .slider .MuiSlider-rail {
    border-radius: 2px;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    background: #484848;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05) inset;
    height: 4px;
  }

  span.MuiSlider-track.css-ttgsjq-MuiSlider-track {
    color: rgba(72, 196, 216, 1);
    height: 4px;
  }
  .range-slider-container .label {
    color: var(--Greys-30, #D5DDE6);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .video-select-container {
      width: 490px;
  }


  .docs-parent, .extra, .links{
    max-width: 1215px !important;
    margin: auto;
    padding: 10px 30px 50px 30px!important;
    background-color: #101926;
  }

  .links {
    padding-bottom: 40px!important;
    padding-top: 30px!important;
  }

  .bottom-section .apps .app-container.hide {display: none;}

  .video-select-container .asset-select .scroller-container {
    width: auto;
}

  .scroller-container .scroller .asset-option.selected {
    outline: 2px solid #48c4d8;
      outline: 2px solid var(--48-c-4-d-8-accentturquoise, #48c4d8);
      outline-offset: -2px;
  }
  .scroller-container .scroller .asset-option {
    transition: 0.3s;
  }
  .scroller-container .scroller .asset-option:hover, .upload-button:hover {
    transform: scale(1.1);
  }
  .scroller-container .scroller {
      overflow: auto;
      height: 100px;
      align-items: center;
  }

  .scroller-container .scroller .asset-option {
      height: 70px!important;
      width: 70px!important;
  }
  .scroller-container .scroller .asset-option img {
    height: 100%;
      object-fit: cover;
      width: auto;
  }

  .multi-photo-select-container .asset-select .scroller-container .scroller .selected {
      border: 0;
  }
  .scroller-container::before, .scroller-container::after {
    display: none;
  }

  .top-section .page-info, #current-asset, .histogram-container, .header, .controls-section .title {
    display: none;
  }

  .controls-section {
    margin-top: 0;
  }
  .controls {
    color: white;
  }

  .video-select-container {
    margin-right: auto;
    margin-left: 0;
  }
  .bottom-section {
      max-width: 965px;
      margin: auto;
      background-color: black;
      padding: 30px;
      width: 100%;
      display: grid!important;
      grid-template-columns: 60% 40%;
  }
  .profile-bar {
    grid-column: span 2;
  }
  .extra {
      background-color: #101926;
      color: #fff;
      color: #d5dde6;
      color: var(--Greys-30, #d5dde6);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      padding: 10px 0px 10px 0px;
  }
  .docs-parent {
    padding: 30px 0px;
    margin-bottom: 60px;
    background-color: rgba(16, 25, 38, 1);
    color: white;
    font-family: "Roboto Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .docs-parent p:nth-of-type(2) {margin-bottom: 30px;}
  .docs-parent p {
     word-break:break-all;
  }
  .orange {
    color: #F7BC00;
  }

.multi-photo-select-container .asset-select .scroller-container {
  overflow: visible;
}
.multi-photo-select-container .asset-select .scroller-container .scroller {
     height: auto; 
    display: flex;
    flex-wrap: nowrap;
    overflow-y: overlay;
    position: relative;
    align-content: flex-start;
    flex-direction: row-reverse;
    overflow: visible;
}
.app-header {display: none;}
.range-slider-container {
  display: grid;
  grid-template-areas:
          "title title"
          "left right";
  padding: 10px;
}
.label {
    grid-area: title;
}

.range-slider-container .slider {
  margin: 0;
  margin-right: 5px;
}
.range-slider-container .value {
  color: white;
  margin-left: 5px;
}


.multi-photo-select-container {
  margin-left: 0;
  margin-right: auto;
}

.top-section, .bottom-section, .links, .hero, .extra, .docs-parent {
  box-sizing: border-box;
  width: 92%;
}


  .links {
    padding-bottom: 40px!important;
  }

  .links {
      padding-bottom: 40px !important;
      color: #FFF;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
  }


  .controls-section .controls .range-slider-container .slider {
    width: 95px;
  }
  .bottom-section {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    video {
      width: auto !important;
    }
  }

  .bottom-section .top-section {
      padding-top: 0 !important;
      background-color: black !important;
  }
  .controls-section .profile, .profile-bar {
    display: none!important;
  }

  .MuiLinearProgress-colorPrimary {
      background-color: #304B6F!important;
  }

  .MuiLinearProgress-bar {
    background-color: #48C4D8!important;
  }

  .control .MuiLinearProgress-root {
    background-color: black !important;
  }

  .control:nth-of-type(1) .MuiLinearProgress-root .MuiLinearProgress-bar{
    background-color: #304B6F !important;
  }



  .top-section {
    border-bottom: none;
    .controls-section {
      flex-direction: column;
      align-items: normal;
      .title {
        border-bottom: 1px solid rgba($black, 0.25);
        width: 100%;
        padding-bottom: 20px;
      }
      .controls {
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 0 30px;
        .control {
          flex-basis: 100%;
          margin-bottom: 30px;
          .control-header {
            font-size: 16px;
            line-height: 1;
            font-weight: 700;
            margin-bottom: 2px;
          }
          .control-info {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-bottom: 10px;
            .browsers {
              display: flex;
              align-items: center;
              margin-left: 18px;
              .browser-logo {
                height: 13px;
                width: 13px;
                margin-right: 4px;
                svg {
                  height: 100%;
                  width: 100%;
                }
              }
            }
            .size {
              margin: 0 12px 0 auto;
              white-space: nowrap;
            }
            .download-button,
            .open-button {
              height: 26px;
              width: 26px;
              margin-right: 10px;
              svg {
                height: 20px;
              }
            }
            .download-button:hover svg path,
            .open-button:hover svg circle {
              fill: #474747;
            }
          }
          .control-bar {
            position: relative;
            .bar-reduced-text {
              position: absolute;
              display: flex;
              right: 10px;
              top: 0;
              line-height: 18px;
              font-size: 13px;
              font-weight: 700;
              z-index: 2;
            }
          }
        }
      }
      .profile {
        display: flex;
        border-bottom: 1px solid rgba($black, 0.25);
        width: 100%;
        padding-bottom: 10px;
        .profile-title {
          margin-right: auto;
        }
      }
    }
  }
  .bottom-section {
    padding-top: 20px;
    .profile-bar {
      padding: 20px 10px 30px;
      margin-top: 20px;
      background-color: $white;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      font-size: 12px;
      font-weight: 700;
      .bar-container {
        display: flex;
        height: 24px;
        margin-top: 10px;
        .profile {
          flex: 1;
          background-color: $grey;
          color: $black;
          text-align: center;
          border-radius: 6px;
          font-size: 11px;
          font-weight: 700;
          line-height: 24px;
          position: relative;
          &:nth-child(2n) {
            background-color: $greyBlue;
          }
          &.active {
            background-color: $primary;
            color: $white;
          }
          &.selected {
            background-color: $purpleLight;
            color: $white;
          }
        }
      }
    }
  }
  @media ($mq-mobile) {
    .controls-section {
      flex-wrap: nowrap;
    }
  }


  @media (max-width: 940px) {
    .bottom-section .top-section {
      padding-top: 40px!important;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }


  @media (max-width: 767px) {
    .top-section {
      flex-wrap: wrap;
      padding: 0;
      padding-top: 10px;
    }
    .asset-select-container {
      overflow: hidden;
    }
    .asset-select .scroller-container {
      overflow: hidden;
    }
    .top-section .controls-section {
      margin-top: 10px;
    }
    .bottom-section {
      flex-wrap: wrap;
    }
    .bottom-section img {
      width: 100%;
    }
    .docs-parent {
      padding-top: 10px;
    }
    .photo-select-container .asset-select {
      padding: 0 10px;
    }
    .top-section:after {
      width: calc(100% - 60px);
    }
    .bottom-section .top-section {
      width: 100%;
    }
  }

  @media (max-width: 940px) {
    .bottom-section {
      display: block!important;
    }
  }




}

.calculating {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  color: $primary;
  background-color: $white;
  padding: 0 10px;
  border-radius: 9px;
  font-size: 13px;
  font-weight: 700;
  height: 16px;
  border: 1px solid $purpleLight;
  .snippet {
    width: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 4px;
  }
  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: $primary;
    color: $primary;
    box-shadow: 9999px 0 0 -3px;
    animation: dot-pulse 1.2s infinite linear;
    animation-delay: 0.15s;
  }
  .dot-pulse::before,
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: $primary;
    color: $primary;
  }
  .dot-pulse::before {
    box-shadow: 9990px 0 0 -3px;
    animation: dot-pulse-before 1.2s infinite linear;
    animation-delay: 0s;
  }
  .dot-pulse::after {
    box-shadow: 10008px 0 0 -3px;
    animation: dot-pulse-after 1.2s infinite linear;
    animation-delay: 0.3s;
  }

  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9990px 0 0 -3px;
    }
    30% {
      box-shadow: 9990px 0 0 1px;
    }
    60%,
    100% {
      box-shadow: 9990px 0 0 -3px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -3px;
    }
    30% {
      box-shadow: 9999px 0 0 1px;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -3px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10008px 0 0 -3px;
    }
    30% {
      box-shadow: 10008px 0 0 1px;
    }
    60%,
    100% {
      box-shadow: 10008px 0 0 -3px;
    }
  }
}
