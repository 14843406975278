@import '~/src/utils/_variables.scss';

.multi-photo-select-container {
  width: calc((136px + 10px) * 3 + 4px);
  margin-left: auto;
  margin-right: -10px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .title {
      font-weight: 700;
    }
    .subtitle {
      font-size: 13px;
      line-height: 14px;
      font-weight: 400;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -15px;
        top: 2px;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        border: 2px solid $greenBright;
        box-sizing: border-box;
      }
    }
  }
  .asset-select {
    display: flex;
    .upload-button {
      width: 136px;
      height: 76px;
      padding: 0;
      flex-shrink: 0;
      background-color: rgba($greyBlue, 0.5);
      border-radius: 8px;
      margin: 0 7px 7px 0;
    }
    .scroller-container {
      overflow: hidden;
      position: relative;
      .scroller {
        height: calc((76px + 7px) * 3);
        display: flex;
        flex-wrap: wrap;
        overflow-y: overlay;
        position: relative;
        align-content: flex-start;
        .asset-option {
          width: 136px;
          height: 76px;
          flex-shrink: 0;
          margin: 0 7px 7px 0;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1);
          overflow: hidden;
        }
        .selected {
          border: 5px solid $primary;
        }
      }
    }
  }
  @media ($mq-mobile) {
    width: 300px;
    margin-left: 0;
  }
}
