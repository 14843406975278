@import '~/src/utils/_variables.scss';

.toggle-switch-container {
  display: flex;
  align-items: center;
  height: 18px;
  margin-left: 25px;
  .switch {
    position: relative;
    display: flex;
    width: 34px;
    height: 18px;
    margin-right: 10px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .slider {
        background-color: $primary;
      }
      &:focus + .slider {
        box-shadow: 0 0 1px $primary;
      }
      &:checked + .slider:before {
        transform: translateX(16px);
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $grey;
      transition: 0.4s;
      border-radius: 9px;
      &::before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 1px;
        bottom: 1px;
        background-color: $white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }
  }
  .label {
    font-size: 13px;
    line-height: 13px;
  }
  @media ($mq-mobile) {
    margin-left: 0;
  }
}
