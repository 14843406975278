@import '~/src/utils/_variables.scss';

.histogram-container {
  .label {
    font-size: 10px;
    font-weight: 700;
  }
  .chart-container {
    max-height: 60px;
    background-color: $white;
    box-shadow: 0 2px 10px rgba(52, 72, 197, 0.1);
    border-radius: 8px;
    padding: 10px 10px 20px;
    margin: 10px 0;
    box-sizing: border-box;
    position: relative;
    .range-ticks {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 6px;
      width: calc(100% - 20px);
      box-sizing: border-box;
      .tick {
        font-size: 10px;
        color: #9d9d9d;
      }
    }
  }
}
